export default {
  namespace: 'railway',
  component: 'bookings',
  meta: {
    title: 'railway.bookings',
    icon: 'recycle'
  },
  tabs: [
    {
      name: 'bookings',
      items: [
        {
          name: 'bookings_list',
          endpoint: '/persistance/default',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'railwayBookings',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                sessionId: true,
                status: true,
                reservationId: true,
                bookingId: true,
                providerName: true,
                // user: true,
                // country: true,
                route: true,
                train: true,
                totalCost: true,
                currency: true,
                depotId: true,
                depotName: true,
                nodeName: true,
                trafficSourceId: true,
                trafficSourceName: true,
                createdAt: true,
                cancelledAt: true,
                expirationTime: true,
                paymentType: true,
                paymentDate: true,
                bookingStatus: true,
                redemptionDate: true,
                comment: true,
                user: {
                  id: true,
                  email: true,
                  phone: true,
                  username: true,
                  isTester: true,
                  depotId: true
                },
                ip: true,
                // show
                departureStation: true,
                arrivalStation: true,
                passengerDepartureStation: true,
                passengerArrivalStation: true,
                trainNumber: true,
                departureDate: true,
                departureTime: true,
                arrivalDate: true,
                arrivalTime: true,
                carType: true,
                carClass: true,
                carNumber: true,
                seats: true,
                bedclothes: true,
                paymentSystem: true,
                cost: true,
                originalCost: true,
                originalCurrency: true,
                serviceCost: true,
                schemeService: true,
                defaultService: true,
                defaultSchemeService: true,
                acquiringService: true,
                transactionCommission: true,
                transactionTotal: true,
                reward: true,
                priceWithoutReward: true,
                vat: true,
                tnxId: true,
                transactionId: true,
                rebooked: true,
                rebookedId: true,
                bonus: true,
                bonusCurrency: true,
                bonusProgramId: true,
                parentId: true,
                childrenId: true,
                issued: true,
                insuranceLocator: true,
                partialRefund: true,
                promotionId: true,
                promotionDiscount: true,
                redeemCodeId: true,
                smsNotificationStatus: true,
                notifyWithSmsAt: true,
                addlInfoJson: true,
                pricerRules: true,

                railwayBookingDocuments: {
                  id: true,
                  railwayBookingId: true,
                  bookingId: true,
                  fiscalNumber: true,
                  firstName: true,
                  lastName: true,
                  passengerDocType: true,
                  passengerDocNumber: true,
                  docType: true,
                  birthday: true,
                  patronymic: true,
                  seatNumber: true,
                  salutation: true,
                  nationality: true,
                  docCost: true,
                  refundStatus: true,
                  refundAmount: true,
                  refundCommission: true,
                  refundDate: true,
                  railwayBookingExtraDocuments: {
                    id: true,
                    railwayBookingDocumentId: true,
                    bookingId: true,
                    luggageVat: true,
                    luggageCost: true,
                    luggageServiceCost: true,
                    luggageType: true,
                    luggageWeight: true,
                    uid: true,
                    fiscalNumber: true,
                    refundAmount: true,
                    refundDate: true,
                    refundStatus: true,
                    schemeService: true,
                    originalCost: true,
                    fullCost: true
                  },
                  railwayBookingDocumentServices: {
                    id: true,
                    railwayBookingDocumentId: true,
                    service: true,
                    cost: true
                  }
                },
                railwayBookingSegments: {
                  id: true,
                  railwayBookingId: true,
                  trainNumber: true,
                  departureStation: true,
                  departureStationCode: true,
                  arrivalStation: true,
                  arrivalStationCode: true,
                  departureDate: true,
                  departureTime: true,
                  arrivalDate: true,
                  arrivalTime: true,
                  travelTime: true,
                  carNumber: true,
                  seats: true,
                  segmentType: true,
                  addlInfoJson: true
                },
                railwayBookingUpsales: {
                  id: true,
                  railwayBookingId: true,
                  name: true,
                  cost: true,
                  currency: true
                },
                // paymentTransactions: {
                //   id: true,
                //   payer: true,
                //   bookingId: true,
                //   createdAt: true,
                //   paymentSystem: true,
                //   transactionId: true,
                //   amount: true,
                //   systemId: true,
                //   status: true,
                //   total: true,
                //   currency: true,
                //   userId: true,
                //   commission: true,
                //   pan4: true,
                //   pan6: true
                // }
              }
            }
          },
          table: {
            items: [
              'selection',
              'id',
              'reservationId',
              'user',
              // 'country',
              'route',
              'totalCost',
              'createdAt',
              'expirationTime',
              // 'paymentType',
              // 'paymentDate',
              'comment',
              'expand'
            ]
          },
          actions: {
            panel: [
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              }
            ]
          },
          filter: {
            fieldsets: [
              {
                legend: 'booking',
                collapsed: false,
                items: [
                  {
                    name: 'id',
                    operator: 'eq',
                    type: 'text',
                    lazy: true
                  },
                  {
                    name: 'booking_id',
                    operator: 'eq',
                    type: 'text',
                    lazy: true
                  },
                  {
                    name: 'session_id',
                    operator: 'eq',
                    type: 'text',
                    lazy: true
                  },
                  {
                    name: 'user_id',
                    operator: 'eq',
                    type: 'text',
                    lazy: true
                  },
                  {
                    name: 'status',
                    operator: 'eq',
                    type: 'select',
                    store: {
                      0: 'Waiting for payment',
                      1: 'Payed',
                      3: 'Cancelled Payed',
                      4: 'Canceled Unpayed',
                      5: 'Error while confirmation',
                      6: 'Error while verification',
                      7: 'Paid after cancellation',
                      8: 'Money returned',
                      9: 'Terminal booking',
                      10: 'Waiting for refund',
                      11: 'Cancellation request',
                      12: 'Cancelled Payment unlocked',
                      15: 'Schedule change',
                      16: 'Price changed',
                      20: 'Refund in process',
                      21: 'Booked paid',
                      22: 'Cancellation failed',
                      23: 'Waiting for reservation payment',
                      24: 'Reservation cancelled',
                      25: 'On hold',
                      26: 'Exchanged'
                    },
                    lazy: true
                  },
                  {
                    name: 'depot_id',
                    operator: 'eq',
                    type: 'select',
                    storeKey: 'depots',
                    storeLabelKeys: ['id', 'name'],
                    lazy: true
                  }
                ]
              }
            ]
          },
          form: {}
        }
      ]
    }
  ]
}