var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('avia-inner-table',{attrs:{"data":(_vm.booking || {}).insuranceBookings || [],"size":"small"}},[_c('el-table-column',{attrs:{"label":"Locator","prop":"reservationId"}}),_c('el-table-column',{attrs:{"label":"Provider","prop":"providerName"}}),_c('el-table-column',{attrs:{"label":"Dates (from/to)","prop":"periodFrom","formatter":function (ref) {
	var periodFrom = ref.periodFrom;
	var periodTo = ref.periodTo;

	return [periodFrom, periodTo].join(' ... ');
}}}),_c('el-table-column',{attrs:{"label":"Cost","prop":"depotCost","formatter":function (ref) {
	var depotCost = ref.depotCost;
	var depotCurrency = ref.depotCurrency;

	return [depotCost, depotCurrency].join(' ');
}}}),_c('el-table-column',{attrs:{"label":"Status","prop":"bookingStatus"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }