import { parseTime, formatTime } from './index.js'

/* Port of strftime(). Compatibility notes:
 *
 * %c - formatted string is slightly different
 * %D - notplemented (use "%m/%d/%y" or "%d/%m/%y")
 * %e - space is not added
 * %E - not implemented
 * %h - not implemented (use "%b")
 * %k - space is not added
 * %n - not implemented (use "\n")
 * %O - not implemented
 * %r - not implemented (use "%I:%M:%S %p")
 * %R - not implemented (use "%H:%M")
 * %t - not implemented (use "\t")
 * %T - not implemented (use "%H:%M:%S")
 * %U - not implemented
 * %W - not implemented
 * %+ - not implemented
 * %% - not implemented (use "%")
 */
function strftime(sFormat, date) {
  if (!(date instanceof Date)) date = new Date();
  var nDay = date.getDay(),
    nDate = date.getDate(),
    nMonth = date.getMonth(),
    nYear = date.getFullYear(),
    nHour = date.getHours(),
    aDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
    isLeapYear = function() {
      if ((nYear&3)!==0) return false;
      return nYear%100!==0 || nYear%400===0;
    },
    getThursday = function() {
      var target = new Date(date);
      target.setDate(nDate - ((nDay+6)%7) + 3);
      return target;
    },
    zeroPad = function(nNum, nPad) {
      return ('' + (Math.pow(10, nPad) + nNum)).slice(1);
    };
  return sFormat.replace(/%[a-z]/gi, function(sMatch) {
    return {
      '%a': aDays[nDay].slice(0,3),
      '%A': aDays[nDay],
      '%b': aMonths[nMonth].slice(0,3),
      '%B': aMonths[nMonth],
      '%c': date.toUTCString(),
      '%C': Math.floor(nYear/100),
      '%d': zeroPad(nDate, 2),
      '%e': nDate,
      '%F': date.toISOString().slice(0,10),
      '%G': getThursday().getFullYear(),
      '%g': ('' + getThursday().getFullYear()).slice(2),
      '%H': zeroPad(nHour, 2),
      '%I': zeroPad((nHour+11)%12 + 1, 2),
      '%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth>1 && isLeapYear()) ? 1 : 0), 3),
      '%k': '' + nHour,
      '%l': (nHour+11)%12 + 1,
      '%m': zeroPad(nMonth + 1, 2),
      '%M': zeroPad(date.getMinutes(), 2),
      '%p': (nHour<12) ? 'AM' : 'PM',
      '%P': (nHour<12) ? 'am' : 'pm',
      '%s': Math.round(date.getTime()/1000),
      '%S': zeroPad(date.getSeconds(), 2),
      '%u': nDay || 7,
      '%V': (function() {
              var target = getThursday(),
                n1stThu = target.valueOf();
              target.setMonth(0, 1);
              var nJan1 = target.getDay();
              if (nJan1!==4) target.setMonth(0, 1 + ((4-nJan1)+7)%7);
              return zeroPad(1 + Math.ceil((n1stThu-target)/604800000), 2);
            })(),
      '%w': '' + nDay,
      '%x': date.toLocaleDateString(),
      '%X': date.toLocaleTimeString(),
      '%y': ('' + nYear).slice(2),
      '%Y': nYear,
      '%z': date.toTimeString().replace(/.+GMT([+-]\d+).+/, '$1'),
      '%Z': date.toTimeString().replace(/.+\((.+?)\)$/, '$1')
    }[sMatch] || sMatch;
  });
}

export default {
  strftime,

  array_as_string: function(value, field, _self){
    return value.join(',')
  },

  date_time: function(value, field, _self){
    if (!value) {
      return value
    }
    return strftime('%Y-%m-%d %H:%M:%S', new Date(value))
  },

  formatted_date_time: function(value, field, _self){
    if (!value) { return value }
    const format = field.formatDate ? field.formatDate : '%Y-%m-%d'
    return strftime(format, new Date(value))
  },

  start_case: function(value, field, _self) {
    return _.startCase(value)
  },

  from_options: function(value, field, _self){
    return _self.translate(''.concat('form.options.',field.name,'.',value))
  },

  from_store: (value, field, _self) => {
    return _self.config.stores[field.storeKey][value]
  },

  from_instance: function(value, field, _self){
    try {
      const store = typeof _self[field.storeKey] !== 'undefined' ? _self[field.storeKey] : _self.preloadStore(field.storeKey)
      return field.storeLabelKeys ? field.storeLabelKeys.map((k) => store[value][k]).join(': ') : store[value][field.storeLabelKey]
    } catch(e) {
      return value
    }
  },
  from_boot: function(value, field, _self){
    try {
      const store = _self.$store.getters['bootData'][field.storeKey]
      return field.storeLabelKeys ? field.storeLabelKeys.map((k) => store[value][k]).join(': ') : store[value][field.storeLabelKey]
    } catch(e) {
      return value
    }
  },

  array_from_boot: function(value, field, _self){
    return value.map((i) => this.from_boot(i, field, _self)).join(',')
  },

  array_from_instance: function(value, field, _self){
    return value.map((i) => this.from_instance(i, field, _self)).join(',')
  },

  formatted_integer: function(value, field, _self){
    return Number.parseInt(value).toLocaleString()
  },

  array_as_string_options: function(value, field, _self){
    return value.map((i) => _self.translate(''.concat('form.options.',field.name,'.',i))).join(',')
  },

  json_string: function(value, field, _self){
    return JSON.stringify(value)
  },

  hash_as_string: function(value, field, _self){
    var val = JSON.parse(value);
    return Object.keys(val).map(key => (key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ') + ": " + val[key])).join(" | ")
  },

}
