export default {
  create(ctx) {
    return {
      row: [
        {
          name: 'update',
          icon: 'el-icon-edit',
          type: 'primary',
          plain: true
        }
      ],
      panel: [
        {
          name: 'create',
          icon: 'el-icon-plus',
          type: 'primary',
          plain: false
        },
        {
          name: 'delete',
          icon: 'el-icon-delete',
          type: 'danger',
          plain: false,
          dependent: 'selection'
        },
        {
          name: 'history',
          icon: 'el-icon-date',
          type: 'primary',
          plain: true,
          params: {
            record_class: 'Uniservice::HotPies::MarketRelation'
          }
        },
        {
          name: 'refresh',
          icon: 'el-icon-refresh',
          type: 'primary',
          plain: true
        },
        {
          name: 'filter',
          context: 'panel',
          icon: 'el-icon-help',
          plain: true
        }
      ]
    }
  }
}