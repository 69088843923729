export default {
  create(ctx) {
    return {
      items: [
        'selection',
        {
          name: 'id',
          width: '60px'
        },
        {
          name: 'service',
          width: '100px'
        },
        {
          name: 'depotId',
          format: 'from_boot',
          storeKey: 'depots_hash',
          storeLabelKey: 'name'
        },
        {
          name: 'updatedAt',
          width: '120px',
          format: 'date_time'
        },
        {
          name: 'active',
          type: 'switch',
          width: '70px'
        },
        {
          name: 'actions',
          width: '125px'
        },
        {
          name: 'expand',
          type: 'expand',
          width: '30px'
        }
      ]
    }
  }
}