<template lang="pug">
  extends /form
</template>

<script>
  import BaseForm from '@crud_view/form'

  export default {
    components: {
      BaseForm
    },
    extends: BaseForm,
    methods: {
      afterCreateCallback(){
        this.$elTab.$emitAction('refreshCombined', this.$options.propsData.row)
      },
      afterUpdateCallback(){
        this.$elTab.$emitAction('refreshCombined', this.$options.propsData.row)
      }
    }
  }
</script>