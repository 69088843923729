<template lang="pug">
  extends /panel
  block right_prepend
      el-form.avia-booking-list-panel(ref="searchForm" v-if="searchByFields" :inline="true")
        el-form-item
          el-select(v-model="search.field" filterable)
            el-option(
              v-for="field in searchByFields"
              :key="field.name"
              :label="translate(`search.${field.name}`)"
              :value="field.name"
          )
        el-form-item
          el-input(v-model="search.value" @keyup.enter.native="actionApplyFilter")
        el-button-group
          el-button(
            @click="actionApplyFilter"
            size="mini"
            plain
            type="primary"
            icon="el-icon-search"
          )

          el-button(
            @click="actionClearSearch"
            size="mini"
            plain
            type="danger"
            icon="el-icon-delete"
          )
</template>

<script>
import BasePanel from '@crud_view/panel'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  extends: BasePanel,
  mixins: [common, di]
}
</script>

<style lang="scss">
.avia-booking-list-panel {
  display: inline-block;
  .el-form-item,
  .el-button-group {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .el-input__inner,
  .el-form-item__content {
    line-height: 1;
  }
  .el-form-item {
    width: 100px;
  }
  .el-button {
    min-width: 40px;
  }
  ~ .el-button,
  .el-button,
  .el-input__inner {
    height: 22px;
  }
}
</style>
