import { render, staticRenderFns } from "./upsale_details.vue?vue&type=template&id=23433184&scoped=true&lang=pug&"
import script from "./upsale_details.vue?vue&type=script&lang=js&"
export * from "./upsale_details.vue?vue&type=script&lang=js&"
import style0 from "./upsale_details.vue?vue&type=style&index=0&id=23433184&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23433184",
  null
  
)

export default component.exports