export default {
  create(ctx) {
    return {
      name: 'uniserviceHotPiesMarketRoutes',
      queries: {
        index: {
          id: true,
          hotPiesMarketId: true,
          name: true,
          active: true,
          departure: true,
          arrival: true,
          createdAt: true,
          updatedAt: true,
          settings: true
        },
        get: {
          id: true,
          hotPiesMarketId: true,
          name: true,
          active: true,
          departure: true,
          arrival: true,
          settings: true
        }
      }
    }
  }
}