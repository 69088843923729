import items from './orders/items'
import operatorSchedule from './orders/issue_queue/operator_schedule/operator_schedule'
import bookingItems from './orders/bookings/items'
import analysisGeneral from './orders/analysis/general'

export default {
  orders: {
    order_items: {
      items: items
    },
    issue_queue: {
      operator_schedule: operatorSchedule
    },
    bookings: {
      items: bookingItems
    },
    analysis: {
      general: analysisGeneral
    }
  }
}
