export default {
  create(ctx) {
  	return {
  	  name: 'Name',
      departure: 'Departure',
      arrival: 'Arrival',
      active: 'Active',
      service: 'Service'
  	}
  }
}