export default {
  avia_analysis_general: {
    form: {
      fields: {
        depotId: 'Market (depot`s)',
        date: 'Date (create booking)',
        trafficSourceId: 'Refid`s',
        gdsConfigItemId: 'Driver`s',
        aviaConfigItemId: 'Config`s',
        createdAt: 'Created at',
        status: 'Status',
        groupOptions: 'Group`s',
        limit: 'Limit (bookings)',
        gdsFrom: 'Get Driver from'
      },
      legends: {
        bookings: 'Bookings'
      }
    },
    table: {
      date: 'Date',
      eTicketed: 'Ticketed',
      eNoTkt: 'No tkt',
      eAgentSavedPercent: '% Agent saved',
      neTicketed: 'Ticketed',
      neNoTkt: 'No tkt',
      neTicketedPercent: '% Ticketed',
      total: 'Total',
      errorsPercent: '% Errors',
      ttlIssuePercent: '% ttl issue'
    }
  }
}