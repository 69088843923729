export default {
  create(ctx) {
    return {
      items: [
        'selection',
        {
          name: 'id',
          width: '60px'
        },
        {
          name: 'name'
        },
        {
          name: 'departure',
          width: '90px'
        },
        {
          name: 'arrival',
          width: '120px'
        },
        {
          name: 'updatedAt',
          width: '120px',
          format: 'date_time'
        },
        {
          name: 'active',
          type: 'switch',
          width: '70px'
        },
        {
          name: 'actions',
          width: '125px'
        }
      ]
    }
  }
}