import bookings from './railway/bookings/bookings'
import railPriceRuleServiceItems from './railway/rail_prices/rail_price_rule_service_items'
import railPriceRuleExtraItems from './railway/rail_prices/rail_price_rule_extra_items'
import railPrices from './railway/rail_prices/rail_prices'
import railSettings from './railway/rail_settings/rail_settings'
import timetable from './railway/timetable/timetable'
import upsaleServices from './railway/upsale_services/upsale_services'
import stations from './railway/stations/stations'
import railRoads from './railway/stations/rail_roads'
import railControl from './railway/rail_control/rail_control'
import plannedMaintenances from './railway/rail_control/planned_maintenances'
import railEuropeProviders from './railway/rail_control/rail_europe_providers'
import railAgents from './railway/rail_agents/rail_agents'
import mcos from './railway/mcos/mcos'

export default {
  railway: {
    bookings: {
      bookings: bookings
    },
    rail_prices: {
      rail_prices: railPrices,
      rail_price_rule_service_items: railPriceRuleServiceItems,
      rail_price_rule_extra_items: railPriceRuleExtraItems
    },
    rail_settings: {
      rail_settings: railSettings
    },
    timetable: {
      timetable: timetable
    },
    upsale_services: {
      upsale_services: upsaleServices
    },
    stations: {
      stations: stations,
      rail_roads: railRoads
    },
    rail_controls: {
      rail_controls: railControl,
      planned_maintenances: plannedMaintenances,
      rail_europe_providers: railEuropeProviders
    },
    rail_agents: {
      rail_agents: railAgents
    },
    mcos: {
      mcos: mcos
    }
  }
}
