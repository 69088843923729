import { DynamicComponentBuilder } from '@/utils'

const hotPiesBuilder = new DynamicComponentBuilder(require.context('./uniservice/hot_pies', true, /\.js$/))

export default {
  uniservice: {
    hot_pies: ['avia'].reduce((tabs, service) => {
      tabs[`hot_pies_${service}`] = hotPiesBuilder.create('tab', { namespace: service })
      return tabs
    }, {})
  }
}