<template lang="pug">
  el-loader(v-if="!records || !records.length" :loading="isLoading" text="Data Loading...")
  el-table(v-else :data="records" border align: 'right')
    
    el-table-column(v-if="shouldShowExpandColumn" type="expand")
      template( #default="props")
        expand-table( v-if="props.row.groupItems" :items="props.row.groupItems" :group="getChildGroup")
    
    el-table-column(prop='groupValue' :label="grouppedColumnName" sortable width='200')
    el-table-column(label='Error' align='center' class="custom-border")
      el-table-tooltiped-column(prop='eTicketed' label='Ticketed' tooltipProp="eTicketedLocators")
      el-table-tooltiped-column(prop='eNoTkt' label='No tkt' tooltipProp="eNoTktLocators")
      el-table-column(prop='eAgentSavedPercent' label='% Agent saved' sortable)
        template(slot-scope="scope")
          div(:style="getBackgroundStyle(scope.row.eAgentSavedPercent, true)") {{scope.row.eAgentSavedPercent}}%
    el-table-column(label='No error' align='center')
      el-table-tooltiped-column(prop='neTicketed' label='Ticketed' tooltipProp="neTicketedLocators")
      el-table-tooltiped-column(prop='neNoTkt' label='No tkt' tooltipProp="neNoTktLocators")
      el-table-column(prop='neTicketedPercent' label='% Ticketed' sortable)
        template(slot-scope="scope")
          div(:style="getBackgroundStyle(scope.row.neTicketedPercent, true)") {{scope.row.neTicketedPercent}}%
    el-table-column(prop='total' label='Total' align='right' sortable)
    el-table-column(prop='errorsPercent' label='% Errors' sortable)
      template(slot-scope="scope")
        div(:style="getBackgroundStyle(scope.row.errorsPercent)") {{scope.row.errorsPercent}}%
    el-table-column(prop='ttlIssuePercent' label='% ttl issue' sortable)
      template(slot-scope="scope")
        div(:style="getBackgroundStyle(scope.row.ttlIssuePercent, true)") {{scope.row.ttlIssuePercent}}%
</template>

<script>
  import Base from '@crud_view/table'
  import ExpandTable from './include/expand_table';
  import ElTableTooltipedColumn from './include/el_table_tooltiped_column';
  export default {
    extends: Base,
    components: { ExpandTable, ElTableTooltipedColumn },
    data(){
      return {
        isLoading: true
      }
    },
    provide() {
      return {
        getParentBackgroundStyle: this.getBackgroundStyle
      }
    },
    computed: {
      shouldShowExpandColumn() {
        return this.records.some((row) => row.groupItems && row.groupItems.length > 0);
      },
      grouppedColumnName(){
        this.isLoading = false;
        return this.records && this.records.length > 0 && this.records[0].groupType.toUpperCase() || 'Groupped by';
      },
      getChildGroup(){
        return this.records && this.records.length > 0 && this.records[0].groupItems.length > 0 && this.records[0].groupItems[0].group_type.toUpperCase()
      }
    },
    methods: {
      getBackgroundStyle(value, isReversed = false) {
        value = Math.min(100, Math.max(0, value));

        let red, green, blue = 180; // Blue stays constant for pastel tones

        if (!isReversed) {
          if (value <= 50) {
            // 0% (Green) -> 50% (Yellow)
            red = Math.round((value / 50) * (255 - 180) + 180);  // Green to Yellow
            green = Math.round((value / 50) * (255 - 255) + 255); // Green stays at max (255)
          } else {
            // 50% (Yellow) -> 100% (Red)
            red = Math.round((value - 50) / 50 * (255 - 255) + 255);  // Red stays at max (255)
            green = Math.round(255 - ((value - 50) / 50) * (255 - 140)); // Yellow to Red (green decreases)
          }
        } else {
          if (value <= 50) {
            // 0% (Red) -> 50% (Yellow)
            red = Math.round((value / 50) * (255 - 255) + 255);  // Red stays at max
            green = Math.round((value / 50) * (255 - 180) + 180); // Red to Yellow
          } else {
            // 50% (Yellow) -> 100% (Green)
            red = Math.round(255 - ((value - 50) / 50) * (255 - 180));  // Red decreases
            green = Math.round(((value - 50) / 50) * (255 - 180) + 180); // Yellow to Green (green increases)
          }
        }
        return {
          backgroundColor: `rgb(${red}, ${green}, ${blue})`,
          color: '#333',
          textAlign: 'center',
          padding: '5px',
          fontWeight: 'bold',
        }
      },
    }
  }
</script>