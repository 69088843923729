export default {
  create(ctx) {
  	return {
      fields: {
        name: 'Name',
        departure: 'Departure',
        arrival: 'Arrival',
        active: 'Active',
        settings__price: 'Price',
        settings__supplier: 'Supplier',
        settings__configId: 'Config ID',
        settings__dateShift: 'Date Shift',
        settings__date: 'Date',
      },
      legends: {
        general: 'General',
        settings: 'Settings'
      },
      placeholder: {
        name: 'Manual name',
        departure: 'Departure code. Example: IEV',
        arrival: 'Arrival code. Example: LWO',
        settings__supplier: 'Price by supplier. Example: PS',
        settings__price: 'Price in depot currency. Example: 90.5',
        settings__configId: 'Search specific config id. Example: 10',
        settings__dateShift: 'Search date (today + VALUE days). Example: 1'
      }
  	}
  }
}