<template lang="pug">
  el-table.avia-booking-buyer-details(:data="userInfo" :show-header="false")
    el-table-column(prop="username" :formatter="({ username }) => (username || '').toUpperCase()")
    el-table-column(prop="email")
      template(slot-scope="props")
        el-link(type="primary" :href="'mailto:'.concat(props.row.email)" target="_blank") {{ props.row.email }}
    el-table-column(prop="phone")
      template(slot-scope="props")
        el-link(type="primary" :href="'tel:+'.concat(props.row.phone)" target="_blank") {{ props.row.phone }}
</template>

<script>
export default {
  props: {
    booking: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    userInfo() {
      const { user, additionalInfo } = this.booking || {}
      const { real_contacts } = JSON.parse(additionalInfo || '{}')

      return this._.uniqBy(
        [user, real_contacts]
          .filter(Boolean)
          .map((u) => ({ ...u, uniq: [u.email, u.phone].join() })),
        'uniq'
      )
    }
  }
}
</script>

<style lang="scss">
.avia-booking-buyer-details {
  color: #334154;
  &::before {
    display: none;
  }
  td.el-table__cell {
    font-size: 14px;
    height: 40px;
  }
  .el-table__body tr:hover > td.el-table__cell,
  .el-table__body tr.hover-row > td.el-table__cell {
    background: #ffffff;
  }
}
</style>
