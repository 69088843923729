export default {
  name: 'general',
  items: [
    {
      name: 'avia_analysis_general',
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: {
        name: 'aviaAnalysisGeneral',
        queries:{
          index: {
            eTicketed: true,
            eNoTkt: true,
            eAgentSavedPercent: true,
            neTicketed: true,
            neNoTkt: true,
            neTicketedPercent: true,
            total: true,
            errorsPercent: true,
            ttlIssuePercent: true,
            groupType: true,
            groupValue: true,
            groupItems: true,
            locators: true,
            eTicketedLocators: true,
            eNoTktLocators: true,
            neTicketedLocators: true,
            neNoTktLocators: true
          }
        }
      },
      pagination: false,
      exclude: ['actions', 'expand'],
      actions:{
        panel: [
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::Analysis::FakeBooking::General'
            }
          }
        ]
      },
      filter:{
        fieldsets:[
          {
            collapsed: false,
            legend: 'bookings',
            scope: 'bookings',
            items: [
                  {
                    name: 'group_options',
                    scope: 'REWRITE_ON_BACKEND',
                    type: 'multiselect',
                    store: {
                      date: 'Date(book create)',
                      depot: 'Market(depot)',
                      driver: 'Driver',
                      traffic: 'Traffic',
                      supplier: 'Supplier(validating)',
                      traffic_brand: 'Traffic brand'
                    },
                    operator: 'eq',
                    default: ['date']
                  },
                  {
                    name: 'limit',
                    scope: 'REWRITE_ON_BACKEND',
                    required: true,
                    operator: 'eq',
                    default: 20000
                  },
                  {
                    name: 'date',
                    operator: 'btw',
                    type: 'daterange',
                    required: true,
                    options: {
                      firstDayOfWeek: 1
                    },
                    valueFormat: 'yyyy-MM-dd',
                    default: ['last week', 'now'],
                  },
                  {
                    name: 'depot_id',
                    type: 'multiselect',
                    storeKey: 'depots',
                    storeLabelKeys: ['id', 'name'],
                    lazy: true,
                    operator: 'in'
                  },
                  {
                    name: 'traffic_source_id',
                    type: 'multiselect',
                    storeKey: 'traffic_sources',
                    storeLabelKeys: ['id', 'name'],
                    lazy: true,
                    operator: 'in'
                  },
                  {
                    name: 'gds_config_item_id',
                    type: 'multiselect',
                    storeKey: 'avia_drivers',
                    storeLabelKeys: ['id', 'name'],
                    operator: 'eq',
                    lazy: true,
                    scope: 'REWRITE_ON_BACKEND'
                  },
                  {
                    name: 'avia_config_item_id',
                    type: 'multiselect',
                    storeKey: 'config_items',
                    storeLabelKeys: ['id', 'name'],
                    operator: 'eq',
                    lazy: true,
                    scope: 'REWRITE_ON_BACKEND'
                  },
                  {
                    name: 'status',
                    required: true,
                    operator: 'in',
                    type: 'multiselect',
                    store: [
                      { id: 'PN', name: 'Payed. Not issued', colorTag: 'avia-status-PN' },
                      { id: 'PC', name: 'Cancelled. Payed', colorTag: 'avia-status-PC' },
                      { id: 'R', name: 'Payed. Refunded', colorTag: 'avia-status-R' },
                      { id: 'P', name: 'Payed. Issued', colorTag: 'avia-status-P' },
                      { id: 'CR', name: 'Payed. Waiting for refund', colorTag: 'avia-status-CR' },
                      { id: 'CL', name: 'Cancelled. Funds unlocked', colorTag: 'avia-status-CL' }
                    ],
                    storeLabelKeys: ['id', 'name'],
                    default: ['CL', 'CR', 'P', 'PC', 'PN', 'R']
                  }
                ]
          }
        ]
      }
    }
  ]
}