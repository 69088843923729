export default {
  create(ctx) {
    return {
      actualize: true,
      fieldsets: [
        this.dcBuilder.create('markets/combined/routes/form/general', ctx),
        this.dcBuilder.create('markets/combined/routes/form/settings', ctx)
      ]
    }
  }
}