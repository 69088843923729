export default {
  create(ctx) {
    return {
      items: [
        {
          name: 'depot_id',
          operator: 'eq',
          type: 'select',
          storeKey: 'depots',
          storeLabelKeys: ['id', 'name'],
          lazy: true,
          scope: 'uniserviceHotPiesMarkets'
        },
      ]
    }
  }
}