<template lang="pug">
  div
    table.avia-booking-trip-location-table(v-for="(segments, tripPartInx) in tripParts" :key="tripPartInx")
      thead
        tr
          th(colspan="5") {{ routeFor(segments) }}
      tbody
        template(v-for="(seg, segmentInx) in segments")
          tr(v-if="segmentInx > 0" :key="'t'.concat(segmentInx)" style="color: #e88b48")
            td(colspan="5" style="padding-top: 2px; padding-bottom: 2px").bold
              template Transfer:&nbsp;
              template {{ [waitingFor(seg, segments[segmentInx - 1]), diffAirportOrTerminal(seg, segments[segmentInx - 1])].filter(Boolean).join(' | ') }}

          tr(:key="'r'.concat(segmentInx)")
            td(style="width: 25%; min-width: 200px")
              div
                template Flight:&nbsp;
                span.bold {{ seg.supplierIata }}-{{ seg.carrier }}
                span(v-if="getFlightInfo(tripPartInx, segmentInx)" style="color: red") ({{ getFlightInfo(tripPartInx, segmentInx) }})
              div
                template Airline:&nbsp;
                span.bold {{ seg.supplierName }}
              div On the way: {{ duration(seg.duration) }}
              div
                template Baggage:&nbsp;
                span.bold(v-html="baggageFor(seg.baggage, tripPartInx, segmentInx)" style="margin-right: 5px")
                i.pointer(
                  v-if="(seg.baggageDetails || []).length"
                  :class="(dispayBaggageInfo[seg.id] && 'el-icon-remove') || 'el-icon-circle-plus'"
                  @click="dispayBaggageInfo = { ...dispayBaggageInfo, [seg.id]: !dispayBaggageInfo[seg.id] }"
                )
              div
                template Carry on:&nbsp;
                span.bold {{ getBaggageType('carry_on', seg.baggageDetails) }}
              div
                template Personal item:&nbsp;
                span.bold {{ getBaggageType('personal_item', seg.baggageDetails) }}
            td(style="width: 25%; min-width: 200px")
              div Aircraft: {{ seg.aircraft }}
              div
                template Class:&nbsp;
                span.bold {{ [seg.bookingClass, seg.bookingClassType].filter(Boolean).join(', ') }}
              div
                template Fare code: {{ seg.fareCode }}
                span(v-if="fareFromFakeBooking(seg.id)" style="color: red") &nbsp;&nbsp;{{ fareFromFakeBooking(seg.id) }}
              template(v-if="booking.virtualInterline")
                div PNR: {{ segmentPnrByPC(seg.id).join(',') }}
                div PCI: {{ segmentPciByPC(seg.id).join(',') }}
            td(style="width: 25%; min-width: 200px")
              div
                template Departure:&nbsp;
                span.bold {{ seg.departureDate }} {{ seg.departureTime }}
              div
                span.bold {{ getDictionaryBy('cities', seg.departureCity) }} 
                template ({{ getDictionaryBy('countries', seg.departureCountry) }})
              div
                template Aeroport: {{ getDictionaryBy('airports', seg.departureLocation) }} ({{ seg.departureTerminal }}) 
                span.bold {{ seg.departureLocation }}
            td(style="width: 25%; min-width: 200px")
              div
                template Arrival:&nbsp;
                span.bold {{ seg.arrivalDate }} {{ seg.arrivalTime }}
              div
                span.bold {{ getDictionaryBy('cities', seg.arrivalCity) }} 
                template ({{ getDictionaryBy('countries', seg.arrivalCountry) }})
              div
                template Aeroport: {{ getDictionaryBy('airports', seg.arrivalLocation) }} ({{ seg.arrivalTerminal }}) 
                span.bold {{ seg.arrivalLocation }}
            td(style="position: sticky; right: 0; width: 72px; min-width: 72px; vertical-align: middle; background-color: #ffffff; box-shadow: -1px 0 1px #ececec;")
              el-row
                el-button(type="primary" icon="el-icon-edit" mini @click="showEditLocationDialog(seg)")
                el-checkbox(:key="seg.id" style="margin-left: 10px" disabled)

          tr(v-if="dispayBaggageInfo[seg.id]" :key="'b'.concat(segmentInx)")
            td(colspan="5" style="padding: 0")
              table.avia-booking-trip-location-table
                tr
                  th(v-for="(head, h) in ['Type', 'PTC', 'Count', 'Weight', 'Width', 'Height', 'Length', 'Dimensions']" style="height: auto" :key="h") {{ head }}
                template(v-for="(sDetails, bIdx) in seg.baggageDetails")
                  tr(v-for="(sItem, sIdx) in sDetails.items" :key="'t'.concat(bIdx, sIdx)")
                    td {{ sDetails.type }}
                    td {{ sItem.pax.join(', ') }}
                    td {{ sItem.count != undefined ? sItem.count : '-' }}
                    td {{ sItem.weight != undefined ? `${sItem.weight} ${fixUnit(sItem.weightUnit)}` : '-' }}
                    td {{ sItem.width != undefined ? `${sItem.width} ${fixUnit(sItem.dimensionUnit)}` : '-' }}
                    td {{ sItem.height != undefined ? `${sItem.height} ${fixUnit(sItem.dimensionUnit)}` : '-' }}
                    td {{ sItem.length != undefined ? `${sItem.length} ${fixUnit(sItem.dimensionUnit)}` : '-' }}
                    td {{ sItem.dimensionSum ? `${sItem.dimensionSum} ${fixUnit(sItem.dimensionUnit)}` : '-' }}

    el-dialog.avia-booking-trip-location-dialog(title="Edit flight information" :visible.sync="editLocationDialog.visible" width="800px")
      el-form(v-loading="editLocationDialog.loading" :model="editLocationDialog.form" :rules="editLocationDialog.rules" label-width="100px")
        el-row(:gutter="20")
          el-col(:span="12")
            el-form-item(label="Trip part" prop="tripPart" size="small")
              el-input-number(v-model="editLocationDialog.form.tripPart")
            el-form-item(label="Segment ID" prop="segmentId" size="small")
              el-input-number(v-model="editLocationDialog.form.segmentId")
            el-form-item(label="Aircompany" prop="supplierIata" size="small")
              el-input(v-model="editLocationDialog.form.supplierIata")
            el-form-item(label="Flight" prop="carrier" size="small")
              el-input(v-model="editLocationDialog.form.carrier")
            el-form-item(label="Baggage" prop="baggage" size="small")
              el-input(v-model="editLocationDialog.form.baggage")
            el-form-item(label="Booking class" prop="bookingClass" size="small")
              el-input(v-model="editLocationDialog.form.bookingClass")
            el-form-item(label="Booking type" prop="bookingClassType" size="small")
              el-select(v-model="editLocationDialog.form.bookingClassType" style="width: 100%")
                el-option(
                  v-for="o in ['economy', 'business', 'first', 'premiumeconomy']" 
                  :label="o"
                  :value="o"
                  :key="o")
            el-form-item(label="Fare code" prop="fareCode" size="small")
              el-input(v-model="editLocationDialog.form.fareCode")
            el-form-item(label="Aircraft" prop="aircraft" size="small")
              el-input(v-model="editLocationDialog.form.aircraft")
          el-col(:span="12")
            fieldset.el-form-fieldset
              legend Departure
              el-form-item(label="Airport" prop="departureLocation" size="small")
                el-input(v-model="editLocationDialog.form.departureLocation")
              el-form-item(label="Terminal" prop="departureTerminal" size="small")
                el-input(v-model="editLocationDialog.form.departureTerminal")
              el-form-item(label="Time" prop="departureDateTime" size="small")
                el-input(v-model="editLocationDialog.form.departureDateTime")
            fieldset.el-form-fieldset
              legend Arrival
              el-form-item(label="Airport" prop="arrivalLocation" size="small")
                el-input(v-model="editLocationDialog.form.arrivalLocation")
              el-form-item(label="Terminal" prop="arrivalTerminal" size="small")
                el-input(v-model="editLocationDialog.form.arrivalTerminal")
              el-form-item(label="Time" prop="arrivalDateTime" size="small")
                el-input(v-model="editLocationDialog.form.arrivalDateTime")
      div(slot="footer")
        el-button(type="primary" size="medium" :disabled="editLocationDialog.loading" plain @click="editLocationDialog.visible = undefined") Dont save and close
        el-button(type="primary" size="medium" :disabled="editLocationDialog.loading" @click="saveLocationDialogForm") Save changes
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  props: {
    booking: {
      type: Object,
      default: () => ({})
    },
    reloadBooking: {
      type: Function,
      default: () => undefined
    }
  },

  data() {
    return {
      editLocationDialog: {
        visible: false,
        loading: false,
        form: {},
        rules: {}
      },
      dispayBaggageInfo: {}
    }
  },

  computed: {
    tripParts() { return this._.groupBy(this.booking.locations, 'tripPart') },
    dictionary() { return JSON.parse(this.booking.dictionary || '{}') },
    priceComponents() { return this.booking.priceComponents || [] },
    additionalInfo() { return JSON.parse(this.booking.additionalInfo || '{}') },
    activePriceComponents() { return this._.filter(this.booking.priceComponents, ['status', 1]) }
  },

  methods: {
    getDictionaryBy(key, code) {
      return (this.dictionary[key] || {})[code] || code
    },

    routeFor(segments) {
      const departure = this._.first(segments)
      const arrival = this._.last(segments)
      const { getDictionaryBy } = this

      return [
        `${getDictionaryBy('cities', departure.departureCity)} (${getDictionaryBy('countries', departure.departureCountry)}), ${getDictionaryBy('airports', departure.departureLocation)}(${departure.departureTerminal})`,
        `${getDictionaryBy('cities', arrival.arrivalCity)} (${getDictionaryBy('countries', arrival.arrivalCountry)}), ${getDictionaryBy('airports', arrival.arrivalLocation)}(${arrival.arrivalTerminal})`
      ].join(' → ')
    },

    duration(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60)
      const minutes = totalMinutes % 60

      return `${hours} hours ${minutes} minutes`
    },

    segmentPnrByPC(segId) {
      let segPnr = []

      this.activePriceComponents.forEach((pc) => {
        if (this._.indexOf(pc.segmentIds, segId) != -1) {
          segPnr.push(pc.locator)
        }
      })

      return segPnr
    },

    segmentPciByPC(segId) {
      let segPci = []

      this.activePriceComponents.forEach((pc, i) => {
        if (this._.indexOf(pc.segmentIds, segId) != -1) {
          segPci.push(i + 1)
        }
      })

      return segPci
    },

    waitingFor(fromSeg, toSeg) {
      const from = this.$moment(`${fromSeg.departureDate} ${fromSeg.departureTime}`, 'DD.MM.YYYY h:mm')
      const to = this.$moment(`${toSeg.arrivalDate} ${toSeg.arrivalTime}`, 'DD.MM.YYYY h:mm')

      return this.duration(from.diff(to, 'minutes'))
    },

    diffAirportOrTerminal({ departureLocation, departureTerminal }, { arrivalLocation, arrivalTerminal }) {
      if (departureLocation !== arrivalLocation || departureTerminal !== arrivalTerminal) {
        return 'Other terminal or airport'
      }
      
      return undefined
    },

    showEditLocationDialog(seg) {
      this.editLocationDialog.visible = true
      this.editLocationDialog.form = {
        ...seg,
        departureDateTime: `${seg.departureDate} ${seg.departureTime}`,
        arrivalDateTime: `${seg.arrivalDate} ${seg.arrivalTime}`
      }
    },

    baggageFor(baggage, tripInx, segInx) {
      let baggageInfo = baggage

      if (this.additionalInfo.fake_baggage) {
        this._.each(this.additionalInfo.fake_baggage, (fakeBaggage) => {
          if (fakeBaggage.trip_inx == tripInx && this._.indexOf(fakeBaggage.sgm_inx, segInx) != -1) {
            baggageInfo = `0PC <span style='color: green; font-weight: bold'>(${fakeBaggage.bg_unit})</span>`
          }
        })
      } else if (this.additionalInfo.substitution_v2 && this.additionalInfo.substitution_v2[tripInx] && this.additionalInfo.substitution_v2[tripInx][segInx]) {
        let ss = this.additionalInfo.substitution_v2[tripInx][segInx]
        if (ss.baggage) {
          baggageInfo = `${baggage} <span style='color: red'> (${ss.baggage})</span>`
        }
      }

      return baggageInfo
    },

    getBaggageType(name, baggage) {
      const { items } = (baggage || []).find(({ type }) => type === name) || {}

      if (!(items || []).length) return '-'

      const { count, weight, weightUnit } = items.reduce(
        (a, { count, weight, weightUnit }) => ({
          ...a,
          count: (a.count || 0) + count,
          weight: (a.weight || 0) + weight,
          weightUnit
        }),
        {}
      )

      return [count, [weight, weightUnit].filter(Boolean).join(' ')].filter(Boolean).join(', ')
    },

    getFlightInfo(i, idx) {
      const { additionalInfo: { substitution_v2: ss } = {} } = this
      const { supplierIATA, carrierNumber } = ((ss || {})[i] || {})[idx] || {}

      return [supplierIATA, carrierNumber].filter(Boolean).join(' - ').trim()
    },

    fareFromFakeBooking(segId) {
      let fare = null

      this.activePriceComponents.forEach((pc, i) => {
        pc.segmentIds.forEach((sId, segInx) => {
          if (sId == segId && pc.realFares[segInx]) {
            fare = pc.realFares[segInx]
          }
        })
      })

      return fare
    },

    fixUnit(unit) {
      return unit || ''
    },

    async saveLocationDialogForm() {
      this.editLocationDialog.loading = true

      const keys = [
        'id',
        'supplierIata',
        'carrier',
        'baggage',
        'bookingClass',
        'bookingClassType',
        'fareCode',
        'aircraft',
        'departureLocation',
        'departureTerminal',
        'departureDateTime',
        'arrivalLocation',
        'arrivalTerminal',
        'arrivalDateTime',
        'tripPart',
        'segmentId'
      ]

      try {
        const query = {
          __args: { ...this._.pick(this.editLocationDialog.form, keys) }
        }

        await this.graphql('aviaBookingItemSaveLocation', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Data successfully saved',
          duration: 2600
        })

        this.editLocationDialog.visible = false

        this.reloadBooking(this.booking.id)
      } catch (e) {
        this.$notify({ type: 'error', message: e })
      } finally {
        this.editLocationDialog.loading = undefined
      }
    }
  }
}
</script>

<style lang="scss">
.avia-booking-trip-location-table {
  color: #334154;
  min-width: 100%;
  font-size: 14px;
  line-height: 1.3;
  border-spacing: 0;
  th,
  td {
    padding: 5px 10px;
    text-align: left;
  }
  th {
    height: 40px;
    background-color: #ffeddf;
  }
  td {
    vertical-align: top;
    border-top: 1px solid #ececec;
  }
}
.avia-booking-trip-location-dialog .el-dialog {
  &__header,
  &__footer {
    padding: 20px 30px;
  }
  &__body {
    padding: 0 30px;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
}
</style>
