<template lang="pug">
  table.avia-booking-old-price-table-details
    thead
      tr
        th(width="51")
        el-tooltip(v-for="({ title, tooltip }, property) in fields" placement="top" :key="property")
          div(slot="content" v-html="[tooltip, fieldCalculationDetails[_.snakeCase(property)]].filter(Boolean).join('<br/><br/>')")
          th(style="white-space: pre; text-align: left") {{ title }}
    tbody
      tr(v-for="currency in priceCalculation.currencies || []" :class="{ 'bold': currency === priceCalculation.payedCurrency }" :key="currency")
        td {{ currency }}
        td(v-for="property in Object.keys(fields)" :key="property" :style="(['netto', 'profitMarkups'].includes(property) && 'background-color: #ffefcf') || ''")
          template {{ (_.find(priceCalculation[property], { currency }) || {}).amount }}
</template>

<script>
export default {
  props: {
    booking: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      fields: {
        netto: {
          title: 'Netto',
          tooltip: 'Netto from gds'
        },
        nettoCorrection: {
          title: 'Netto\ncorrect.',
          tooltip: 'Difference between search and booking'
        },
        profitMarkups: {
          title: 'Profit\nmark.',
          tooltip: `Dynamic<sup>1</sup> + Markups<sup>2</sup> + Auction<sup>3</sup> + FF comm<sup>4</sup><br/><br/>
                   <sup>1</sup> dynamic from price_details<br/>
                   <sup>2</sup> rl[0] + df.rl[0] from price_details<br/>
                   <sup>3</sup> rl[5] + df.rl[5] from price_details<br/>
                   <sup>4</sup> rl[7] + df.rl[7] from price_details`
        },
        paymentMarkups: {
          title: 'Pay.\nmark.',
          tooltip: `Discount - Promocode + Payment commission<sup>1</sup> + Transaction commission<sup>2</sup><br/><br/>
                   <sup>1</sup> from pricer table<br/>
                   <sup>2</sup> if present service pack or transaction commission`
        },
        surchargeFlight: {
          title: 'Surch.\nflight',
          tooltip: 'Mco surcharges with reasons:<br />tarif_change, free_seats, chd_tarif, client_surcharge, pay_to_us, add_infant'
        },
        surchargeAddons: {
          title: 'Surch.\nadd-ons',
          tooltip: 'Mco surcharges with reasons:<br />meal_surcharge, baggage_surcharge, seat_surcharge, surcharge_free_text, additional_services'
        },
        surchargePostsale: {
          title: 'Surch.\npost sale',
          tooltip: 'Mco surcharges with reasons:<br />pass_not_correct, docs_change'
        },
        paymentMethodMarkup: {
          title: 'Pay. met.\nmark.',
          tooltip: 'Payment commisssion without service pack'
        },
        alternativeCurrencyMarkup: {
          title: 'Alt. curr.\nmarkup',
          tooltip: 'Service pack <sup>1</sup><br/><br/><sup>1</sup> from discount_info -> service_pack_cash_depot'
        },
        flightTotal: {
          title: 'Flight\ntotal',
          tooltip: 'Transaction total - Bonus - Promocode + Transaction commission + Surcharge flight'
        },
        upsale: {
          title: 'Upsale',
          tooltip: 'Non GDS additional services'
        },
        ancillary: {
          title: 'Ancill.',
          tooltip: 'GDS additional services'
        },
        insurance: {
          title: 'Insur.',
          tooltip: 'Insurance'
        },
        diffMarkup: {
          title: 'Diff.\nmark.',
          tooltip: 'Additional discount'
        },
        payedTotal: {
          title: 'Payed\ntotal',
          tooltip: 'Payment transaction'
        }
      }
    }
  },

  computed: {
    priceCalculation({ booking }) {
      return (booking || {}).priceCalculation || {}
    },
    fieldCalculationDetails({ priceCalculation: { fieldCalculationDetails } }) {
      return JSON.parse(fieldCalculationDetails || '{}')
    }
  }
}
</script>

<style lang="scss">
.avia-booking-old-price-table-details {
  min-width: 100%;
  color: #334154;
  line-height: 1.2;
  border-spacing: 0;
  th,
  td {
    padding: 5px 10px;
    &:first-child {
      box-shadow: 1px 0 1px #ececec;
      position: sticky;
      left: 0;
    }
  }
  th {
    background-color: #f5f5f5;
  }
  td {
    font-size: 14px;
    background-color: #ffffff;
    transition: background-color 0.15s ease;
  }
  tr:hover td {
    background-color: #f5f5f5;
  }
  tr:not(:last-child) td {
    border-bottom: 1px solid #ececec;
  }
}
</style>
