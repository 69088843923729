export default {
  create(ctx) {
    return {
      row: [
        {
          name: 'update',
          icon: 'el-icon-edit',
          type: 'success',
          plain: true
        }
      ],
      panel: [
        {
          name: 'create',
          icon: 'el-icon-plus',
          type: 'success',
          plain: false
        },
        {
          name: 'delete',
          icon: 'el-icon-delete',
          type: 'danger',
          plain: false,
          dependent: 'selection'
        },
        {
          name: 'history',
          icon: 'el-icon-date',
          type: 'primary',
          plain: false,
          params: {
            record_class: 'Uniservice::HotPies::MarketRouteRelation'
          }
        },
      ]
    }
  }
}