<template lang="pug">
  extends /table
</template>

<script>
  import BaseTable from '@crud_combined/table'

  export default {
    components: {
      BaseTable
    },
    extends: BaseTable,
    methods: {
      actionHistory(params) {
        this.$elTab.$emitAction('history', params)
      },
      afterDeleteCallback(){
        this.$elTab.$emitAction('refreshCombined', this.$options.propsData.row)
      }
    }
  }
</script>

<style lang="scss">
  .hot-pies-routes {
    .filter__right {
      margin-right: 0px !important;
    }
    .filter-form {
      .form-buttons {
        span {
          display: none;
        }
      }
    }
  }
</style>