import { DynamicComponentBuilder } from '@/utils'

const hotPiesBuilder = new DynamicComponentBuilder(require.context('./hot_pies', true, /\.js$/))

export default {
  namespace: 'uniservice',
  component: 'hot_pies',
  meta: {
    title: 'uniservice.hot_pies',
    icon: 'star'
  },
  tabs: ['avia'].map((service) => {
    return hotPiesBuilder.create('tab', { namespace: service })
  })
}