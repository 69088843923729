export default {
  reports_list: {
    table: {
      bid: 'BID',
      setting: {
        name: 'Setting Name'
      }
    },
    action: {
      download: 'Download report'
    }
  }
}