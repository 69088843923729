export default {
  create(ctx) {
    return {
      range: {
        '1': '1 Day',
        '2': '2 Days',
        '3': '3 Days',
        '7': '7 Days',
        '14': '14 Days',
        '21': '21 Days',
        '28': '28 Days',
        '35': '35 Days',
        '42': '42 Days'
      }
    }
  }
}