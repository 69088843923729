<template lang="pug">
  extends /panel
  block right_prepend
    el-button(type="plain" :disabled="isDisabled()" @click="handleAction('showTransactions')" icon="el-icon-bank-card") {{ translate('panel.transactions') }}
    span &nbsp;
    el-button(type="plain" :disabled="isDisabled()" @click="handleAction('showComments')" icon="el-icon-chat-line-round") {{ translate('panel.comments') }}
    span &nbsp;
    el-dropdown(trigger="click" @command="changeStatus")
      el-button(type="plain" :disabled="isDisabled()" icon="el-icon-help") {{ translate('panel.change_status') }}
        i(class="el-icon-arrow-down el-icon--right")

      el-dropdown-menu(slot="dropdown")
        el-dropdown-item(
          v-for="(key, value) in statuses"
          :key="value"
          :command="value"
        ) {{ key }}
    span &nbsp;

    el-dropdown(trigger="click" @command="handleCommand")
      el-button(type="plain" :disabled="isDisabled()" icon="el-icon-cold-drink") {{ translate('panel.files') }}
        i(class="el-icon-arrow-down el-icon--right")

      el-dropdown-menu(slot="dropdown")
        el-dropdown-item(icon="el-icon-download" command="download") {{ translate('panel.file.download') }}
        el-dropdown-item(icon="el-icon-refresh" command="regenerateFile") {{ translate('panel.file.regenerate') }}
        el-dropdown-item(icon="el-icon-chat-dot-square" command="addCommentHandler" divided) {{ translate('panel.file.addComment') }}
        el-dropdown-item(icon="el-icon-medal-1" command="bookingStatus") {{ translate('panel.file.bookingStatus') }}
        el-dropdown-item(icon="el-icon-delete" command="removeParentId" divided) {{ translate('panel.file.removeParentId') }}
        el-dropdown-item(icon="el-icon-warning" command="removeChildId") {{ translate('panel.file.removeChildId') }}
        el-dropdown-item(icon="el-icon-printer" command="autoConfirmBooking" divided) {{ translate('panel.file.autoConfirmBooking') }}
        el-dropdown-item(icon="el-icon-printer" command="confirmBooking") {{ translate('panel.file.confirmBooking') }}
    span &nbsp;
</template>

<script>
import BasePanel from '@crud_view/panel'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  extends: BasePanel,
  mixins: [common, di],
  data() {
    return {
      enabled: [],
      statuses: {
        0: 'Unpaid',
        1: 'Paid',
        7: 'Paid after cancellation',
        8: 'Money returned',
        10: 'Waiting for refund'
      }
    }
  },
  methods: {
    changeStatus(status) {
      console.log(status)
      this.$elItem.$emitAction('changeStatus', status)
    },
    handleCommand(command) {
      this.$elItem.$emitAction(command)
    },
    handleAction(actionName) {
      this.$elItem.$emitAction(actionName)
    },
    eventSelectionChange(val) {
      this.enabled = [val]
    },
    isDisabled() {
      return !this.enabled.includes(true)
    }
  }
}
</script>
