export default {
  name: 'seats_block_rules',
  meta: {
    title: 'rules',
    icon: 'skill',
    permission: 'rules'
  },
  lazy: true,
  items: [
    {
      name: 'seats_block_rules',
      endpoint: '/persistance/default',
      // endpoint: '/persistance/general/ttn_service',
      type: 'graphql',
      graphql: {
        name: 'aviaSeatsBlockRules',
        queries: {
          index: {
            id: true,
            routeKey: true,
            validatingSupplier: true,
            issueOfficeId: true,
            availableSeats: true,
            remainedSeats: true,
            startOfSales: true,
            flights: true,
            fares: true,
            fareFamilies: true,
            comment: true,
            currency: true,
            active: true,
            updatedAt: true,
            flightsLegId: false,
            flightsSegmentIndex: false,
            flightsRbd: false,
            contractId: true,
            lastSaleTime: true,
            saleBeforeDate: false,
            locator: true,
            departureDate: true,
            checkPrice: true,
            checkPriceParams: {
              configId: true
            },
            currentPrice: true,
            lastCheckPriceTime: true,
            configKey: true,
            fareConditions: true,
            features: {
              isLcc: true,
              isCharter: true,
              showSeatsCount: true
            },
            locatorConfigId: true,
            lastCheckTime: true,
            blockRemainedUpdate: true,
            childRules: true,
            blockSegmentsUpdate: true
          }
        }
      },
      table: {
        items: [
          'selection',
          {
            name: 'id',
            width: '60px'
          },
          {
            name: 'routeKey'
          },
          {
            name: 'validatingSupplier',
            width: '70px',
            sortable: false
          },
          {
            name: 'remainedSeats',
            width: '60px'
          },
          {
            name: 'priceDiff',
            width: '75px',
            sortable: false
          },
          {
            name: 'departureDate',
            width: '80px'
          },
          {
            name: 'lastSaleTime',
            width: '100px',
            format: 'date_time'
          },
          {
            name: 'issueOfficeId',
            width: '60px'
          },
          {
            name: 'contractId',
            width: '80px'
          },
          {
            name: 'active',
            type: 'switch',
            width: '70px'
          },
          // {
          //   name: 'updatedAt',
          //   width: '120px',
          //   format: 'date_time'
          // },
          {
            name: 'actions',
            width: '125px'
          },
          {
            name: 'expand',
            type: 'expand',
            width: '30px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          },
          {
            name: 'clone',
            icon: 'el-icon-document-copy',
            type: 'info',
            plain: true
          },
          {
            name: 'manage_seats',
            icon: 'el-icon-magic-stick',
            type: 'warning',
            plain: true
          }
        ],
        panel: [
          {
            name: 'group_change',
            icon: 'el-icon-s-flag',
            type: 'info',
            plain: true
          },
          {
            name: 'check_in_gds',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true
          },
          {
            name: 'check_price',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Avia::SeatsBlock::RuleRelation'
            }
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        items: [
          {
            name: 'id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'validating_supplier',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'issue_office_id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'remained_seats',
            type: 'text',
            operators: ['eq', 'gt', 'gte', 'lt', 'lte']
          },
          {
            name: 'config_key',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'contract_id',
            type: 'text',
            operator: 'eq'
          },
          {
            name: 'route_key',
            type: 'text',
            operator: 'like'
          },
          {
            name: 'flights__marketing_flight_number',
            type: 'text',
            operator: 'in_array_hash_eq',
            valueType: 'integer'
          },
          {
            name: 'departure_date',
            operator: 'btw',
            type: 'daterange',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            required: false
          }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'route_key',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'flights',
            type: 'hidden',
            required: false,
            valueType: 'json_as_string'
          },
          {
            name: 'remained_seats',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'fares',
            type: 'hidden',
            required: false,
            valueType: 'json_as_string'
          },
          {
            name: 'fare_families',
            type: 'hidden',
            required: false,
            valueType: 'json_as_string'
          },
          {
            name: 'active',
            type: 'checkbox',
            default: false,
            required: false
          },
          {
            name: 'validating_supplier',
            required: true
          },
          {
            name: 'available_seats',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'start_of_sales',
            type: 'date',
            options: {
              firstDayOfWeek: 1
            },
            valueFormat: 'yyyy-MM-dd',
            required: false
          },
          {
            name: 'issue_office_id',
            required: true,
            valueType: 'integer'
          },
          {
            name: 'contract_id',
            required: false
          },
          {
            name: 'locator',
            required: false
          },
          {
            name: 'sale_before_date',
            required: true,
            valueType: 'integer',
            default: 0
          },
          {
            name: 'comment',
            type: 'textarea',
            required: false,
            rows: 3,
            default: ''
          },
          {
            name: 'currency',
            required: true
          },
          {
            name: 'config_key',
            required: true
          },
          {
            name: 'fare_conditions',
            type: 'textarea',
            required: false,
            rows: 3,
            default: ''
          },
          {
            name: 'flights__leg_id',
            required: false,
            ignore: true
          },
          {
            name: 'flights__segment_index',
            required: false,
            ignore: true
          },
          {
            name: 'flights__departure_location',
            required: false,
            ignore: true
          },
          {
            name: 'flights__arrival_location',
            required: false,
            ignore: true
          },
          {
            name: 'flights__departure_time',
            type: 'datetime',
            options: {
              firstDayOfWeek: 1
            },
            required: false,
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            valueType: 'datetime_no_tz',
            ignore: true
          },
          {
            name: 'flights__arrival_time',
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            valueType: 'datetime_no_tz',
            options: {
              firstDayOfWeek: 1
            },
            required: false,
            ignore: true
          },
          {
            name: 'flights__marketing_supplier',
            required: false,
            ignore: true
          },
          {
            name: 'flights__marketing_flight_number',
            required: false,
            ignore: true
          },
          {
            name: 'flights__operating_supplier',
            required: false,
            ignore: true
          },
          {
            name: 'flights__operating_flight_number',
            required: false,
            ignore: true
          },
          {
            name: 'flights__rbd',
            required: false,
            ignore: true
          },
          {
            name: 'flights__departure_terminal',
            required: false,
            ignore: true
          },
          {
            name: 'flights__arrival_terminal',
            required: false,
            ignore: true
          },
          {
            name: 'flights__aircraft',
            required: false,
            ignore: true
          },
          {
            name: 'flights__rbd_type',
            type: 'select',
            storeLabelKeys: ['name'],
            store: [
              { id: 'economy', name: 'economy' },
              { id: 'premium_economy', name: 'premium_economy' },
              { id: 'business', name: 'business' },
              { id: 'first', name: 'first' }
            ],
            default: 'economy',
            style: 'width: 300px',
            required: false,
            ignore: true
          },
          {
            name: 'fares__name',
            required: false,
            ignore: true
          },
          {
            name: 'fares__fare',
            required: false,
            ignore: true,
            valueType: 'float'
          },
          {
            name: 'fares__taxes',
            required: false,
            ignore: true,
            valueType: 'float'
          },
          {
            name: 'fares__total',
            required: false,
            ignore: true,
            valueType: 'float'
          },
          {
            name: 'fares__fare_code',
            required: false,
            ignore: true
          },
          {
            name: 'fares__baggage',
            required: false,
            ignore: true
          },
          {
            name: 'fares__baggage_details__dimension_sum',
            required: false,
            ignore: true
          },
          {
            name: 'fares__baggage_details__dimension_unit',
            required: false,
            ignore: true
          },
          {
            name: 'fares__baggage_details__weight',
            required: false,
            ignore: true,
            valueType: 'integer'
          },
          {
            name: 'fares__baggage_details__weight_unit',
            required: false,
            ignore: true
          },
          {
            name: 'fares__baggage_details__count',
            required: false,
            ignore: true,
            valueType: 'integer'
          },
          {
            name: 'fare_families__fare',
            required: false,
            ignore: true,
            valueType: 'float'
          },
          {
            name: 'fare_families__taxes',
            required: false,
            ignore: true,
            valueType: 'float'
          },
          {
            name: 'fare_families__total',
            required: false,
            ignore: true,
            valueType: 'float'
          },
          {
            name: 'fare_families__name',
            required: false,
            ignore: true
          },
          {
            name: 'fare_families__fare_code',
            required: false,
            ignore: true
          },
          {
            name: 'fare_families__baggage',
            required: false,
            ignore: true
          },
          {
            name: 'fare_families__baggage_details__dimension_sum',
            required: false,
            ignore: true
          },
          {
            name: 'fare_families__baggage_details__dimension_unit',
            required: false,
            ignore: true
          },
          {
            name: 'fare_families__baggage_details__weight',
            required: false,
            ignore: true,
            valueType: 'integer'
          },
          {
            name: 'fare_families__baggage_details__weight_unit',
            required: false,
            ignore: true
          },
          {
            name: 'fare_families__baggage_details__count',
            required: false,
            ignore: true,
            valueType: 'integer'
          },
          {
            name: 'check_price',
            type: 'checkbox',
            default: false,
            required: false
          },
          {
            name: 'check_price_params__config_id',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'features__is_lcc',
            type: 'checkbox',
            default: false,
            required: false
          },
          {
            name: 'features__is_charter',
            type: 'checkbox',
            default: false,
            required: false
          },
          {
            name: 'features__show_seats_count',
            type: 'checkbox',
            default: false,
            required: false,
            valueType: 'boolean'
          },
          {
            name: 'locator_config_id',
            required: false,
            valueType: 'integer'
          },
          {
            name: 'block_remained_update',
            type: 'checkbox',
            default: false,
            required: false
          },
          {
            name: 'child_rules',
            required: false,
            valueType: 'array_of_integer'
          },
          {
            name: 'block_segments_update',
            type: 'checkbox',
            default: false,
            required: false
          }
        ]
      },
      collapsibleCombine: true,
      combined: [
        {
          name: 'seats_block_rules_history',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'loadHistory',
            queries: {
              index: {
                time: true,
                recordId: true,
                operation: true,
                userLogin: true,
                changes: true
              }
            }
          },
          boot: {
            type: 'remote'
          },
          combineParams:{
            class: 'asb-cl-old',
            expanded: false
          },
          table: {
            items: [
              {
                name: 'time'
              },
              {
                name: 'userLogin'
              },
              {
                name: 'locator'
              },
              {
                name: 'remained_seats_before'
              },
              {
                name: 'seats_count'
              },
              {
                name: 'remained_seats_after'
              }
            ]
          }
        },
        {
          name: 'seats_block_rule_booking',
          endpoint: '/persistance/default',
          type: 'graphql',
          graphql: {
            name: 'aviaSeatsBlockRuleBookings',
            queries: {
              index: {
                bookingId: true,
                orderLocator: true,
                locator: true,
                status: true,
                remainBefore: true,
                seatsCount: true,
                remainAfter: true,
                sessionId: true,
                backendUser: {
                  id: true,
                  login: true
                },
                backendUserId: true,
                createdAt: true,
                updatedAt: true
              }
            }
          },
          boot: {
            type: 'remote',
            filters: ['id'],
            filters_mapping: {
              id: 'avia_seats_block_rule_id'
            }
          },
          combineParams:{
            class: 'asb-cl-new'
          },
          table: {
            items: [
              {
                name: 'updatedAt',
                format: 'date_time',
                width: '120px'
              },
              {
                name: 'orderLocator',
                width: '120px'
              },
              {
                name: 'locator',
                width: '90px'
              },
              {
                name: 'status',
                format: 'from_options'
              },
              {
                name: 'remainBefore',
                width: '120px'
              },
              {
                name: 'seatsCount',
                width: '100px'
              },
              {
                name: 'remainAfter',
                width: '120px'
              },
              {
                name: 'backendUser'
              },
              {
                name: 'expand',
                type: 'expand',
                width: '30px'
              }
            ]
          }
        }
      ]
    }
  ]
}
