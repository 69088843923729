export default {
  name: 'avia_config_groups',
  items: [
    {
      name: 'avia_config_group_list',
      endpoint: '/persistance/default',
      pagination: true,
      autoLoad: true,
      type: 'graphql',
      graphql: {
        name: 'radarAviaConfigGroup',
        queries: {
          index: {
            id: true,
            name: true,
            aviaConfigIds: true,
            createdAt: true,
            updatedAt: true,
          }
        },
        preloadFields: ['aviaConfigs{id name}']
      },
      preload: true,
      preloadStores: ['aviaConfigs'],
      table: {
        class: 'radar-avia-config-groups-table',
        items: [
          'selection',
          { name: 'id', width: '50px' },
          { name: 'name', width: '250px'},
          {
            name: 'aviaConfigIds',
            format: 'array_from_instance',
            storeKey: 'aviaConfigs',
            storeLabelKeys: ['id', 'name'],
            type: 'tagList'
          },
          {
            name: 'createdAt',
            sortable: false,
            width: '200px'
          },
          {
            name: 'actions',
            width: '75px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'create',
            icon: 'el-icon-plus',
            type: 'primary',
            plain: false
          },
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Radar::AviaConfigGroupRelation'
            }
          }
        ]
      },
      form: {
        actualize: true,
        items: [
          {
            name: 'id',
            type: 'hidden',
            required: false,
            valueType: 'integer'
          },
          'name',
          {
            name: 'aviaConfigIds',
            type: 'multiselect',
            format: 'array_of_integer',
            storeKey: 'aviaConfigs',
            storeLabelKeys: ['id', 'name']
          },          
        ]
      }
    }
  ]
}
