export default {
  create(ctx) {
    return [
      {
        name: `hot_pies_${ctx.namespace}_market_routes`,
        endpoint: '/persistance/default',
        type: 'graphql',
        graphql: this.dcBuilder.create('markets/combined/routes/graphql', ctx),
        table: this.dcBuilder.create('markets/combined/routes/table', ctx),
        actions: this.dcBuilder.create('markets/combined/routes/actions', ctx),
        form: this.dcBuilder.create('markets/combined/routes/form', ctx),
        pagination: false,
        boot: {
          type: 'remote',
          filters: ['id'],
          filters_mapping: {
            id: 'hot_pies_market_id'
          },
          relation: {
            hotPiesMarketId: 'id'
          }
        },
        combineParams:{
          class: 'hot-pies-routes',
          expanded: true
        }
      }
    ]
  }
}