<template lang="pug">
  el-table-column(:prop="prop" :label="label" :align="align" :sortable="sortable")
    template(slot-scope="scope")
      el-tooltip(v-if="showTooltip(scope.row)" :effect="effect" :content="tooltipData(scope.row)" width="300")
        span {{scope.row[prop]}}
      span(v-else) {{scope.row[prop]}}
</template>

<script>
  export default{
    name: 'el-table-tooltiped-column',
    props:{
      prop:         {type: String, required: true},
      tooltipProp:  {type: String, required: true},
      label:        {type: String, required: true},
      sortable:     {type: Boolean, default: true},
      align:        {type: String, default: 'right'},
      effect:       {type: String, dafault: 'dark'}
    },
    methods:{
      tooltipData(scopeRow){
        let str = scopeRow[this.tooltipProp].join(', ')
        return str.length > 100 ? str.slice(0,100)+'...' : str
      },
      showTooltip(scopeRow){
        return scopeRow[this.tooltipProp] && scopeRow[this.tooltipProp].length > 0
      }
    }
  }
</script>