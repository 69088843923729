<template lang="pug">
  div
    el-dialog.avia-booking-passengers-dialog(
      title="Edit passenger information"
      :visible.sync="editPaxDialog.visible"
      v-loading="editPaxDialog.loading" 
      element-loading-text="Loading..."
      width="100%"
    )
      el-form(:model="editPaxDialog.form" :rules="editPaxDialog.rules" label-position="left" label-width="100px")
        el-row(:gutter="40")
          el-col(:xs="24" :sm="8")
            .avia-booking-passengers-dialog__title.bold Passenger
            el-form-item(label="First name" prop="firstName")
              el-input(v-model="editPaxDialog.form.firstName" size="small")
            el-form-item(label="Last name" prop="lastName")
              el-input(v-model="editPaxDialog.form.lastName" size="small")
            el-form-item(label="Patronymic" prop="patronymic")
              el-input(v-model="editPaxDialog.form.patronymic" size="small")
            el-form-item(label="Date of birth" prop="birthDay")
              el-date-picker(v-model="editPaxDialog.form.birthDay" type="date" size="small" placeholder="Pick a day" format="dd-MM-yyyy")
            el-form-item(label="Sex" prop="gender")
              el-select(v-model="editPaxDialog.form.gender" size="small")
                el-option(v-for="o in ['M', 'F', 'MI', 'FI']" :label="o" :value="o" :key="o")
          el-col(:xs="24" :sm="8")
            .avia-booking-passengers-dialog__title.bold Document
            el-form-item(label="Document" prop="passportNumber")
              el-input(v-model="editPaxDialog.form.passportNumber" size="small")
            el-form-item(label="Doc. exp. date" prop="passportTerm")
              el-date-picker(v-model="editPaxDialog.form.passportTerm" type="date" size="small" placeholder="Pick a day" format="dd-MM-yyyy")
            el-form-item(label="Nationality" prop="countryId")
              el-select(v-model="editPaxDialog.form.countryId" size="small")
                el-option(v-for="o in countryList" :label="`${o.name} (${o.code})`" :value="o.code" :key="o.code")
          el-col(:xs="24" :sm="8")
            .avia-booking-passengers-dialog__title.bold Fare and Final Cost
            el-form-item(label="Tariff")
              el-input(v-model="editPaxDialog.form.amounts.values.tarif" size="small")
            el-form-item(label="Equivalent")
              el-input(v-model="editPaxDialog.form.amounts.values.equiv" size="small")
            el-form-item(label="Taxes")
              el-input(v-model="editPaxDialog.form.amounts.values.taxes" size="small")
            el-form-item(label="Currency")
              el-input(v-model="editPaxDialog.form.amounts.currency" size="small")
            el-form-item(label="Bonus card" prop="bonusCard")
              el-input(v-model="editPaxDialog.form.bonusCard" size="small")
      div(slot="footer")
        el-button(type="primary" size="medium" plain @click="editPaxDialog.visible = undefined") Dont save and close
        el-button(type="primary" size="medium" @click="savePaxDialogForm()") Save changes

    avia-inner-table(:data="(booking || {}).passengers || []")
      el-table-column(label="PT" prop="ageType" :formatter="({ ageType, ...pax }) => [ageType, calculateAge({ ageType, ...pax })].filter(Boolean).join(' ')")
      el-table-column(label="Last name" prop="lastName" min-width="150" show-overflow-tooltip)
      el-table-column(label="First name" prop="firstName" min-width="150" show-overflow-tooltip)
      el-table-column(label="Patronymic" prop="patronymic" min-width="130" :formatter="({ patronymic }) => patronymic || '-'" show-overflow-tooltip)
      el-table-column(label="Date of birth" prop="birthDay" width="100" :formatter="dateFormatter")
      el-table-column(label="Gender" prop="gender" width="70")
      el-table-column(label="Citizenship" prop="countryId" min-width="100" :formatter="({ countryId }) => ''.concat(getDictionaryBy('countries', countryId), ' (', countryId, ')')")
      el-table-column(label="Passport" prop="passportNumber" min-width="100")
      el-table-column(label="Doc. exp. date" prop="passportTerm" width="100" :formatter="dateFormatter")
      el-table-column(label="Bonus card" prop="bonusCard" :formatter="({ bonusCard }) => bonusCard || '-'")
      el-table-column(width="48" fixed="right")
        template(slot-scope="props")
          el-button(type="primary" icon="el-icon-edit" @click="showEditPaxDialog(props.row)")
</template>

<script>
// import BaseTable from '@crud_view/table'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

import AviaInnerTable from './_inner_table'

export default {
  components: { AviaInnerTable },

  mixins: [common, di],

  props: {
    booking: {
      type: Object,
      default: () => ({})
    },
    reloadBooking: {
      type: Function,
      default: () => undefined
    }
  },

  data() {
    return {
      editPaxDialog: {
        visible: false,
        loading: false,
        form: { amounts: { values: {} } },
        rules: {}
      },
      countryList: []
    }
  },

  computed: {
    dictionary: ({ booking }) => JSON.parse((booking || {}).dictionary || '{}')
  },

  methods: {
    getDictionaryBy(key, code) {
      return (this.dictionary[key] || {})[code] || code
    },

    dateFormatter(row, column, value) {
      const date = Number(value) * 1000

      return (date && this.$moment(date).format('DD-MM-YYYY')) || '-'
    },

    calculateAge({ ageType, birthDay }) {
      if (!['CHD', 'INF'].includes(ageType)) return ''

      const [{ arrivalDate, arrivalTime }] = (this.booking || {}).locations || []
      const birthday = this.$moment(Number(birthDay) * 1000)
      const date = this.$moment(`${arrivalDate} ${arrivalTime}`, 'DD.MM.YYYY h:mm')
      const diff = date.diff(birthday, 'month')

      return `${Math.floor(diff / 12)}y${diff % 12}m`
    },

    showEditPaxDialog(pax) {
      this.editPaxDialog.visible = true
      this.editPaxDialog.form = {
        id: pax.id,
        gender: pax.gender,
        birthDay: this.$moment(pax.birthDay),
        passportNumber: pax.passportNumber,
        passportTerm: this.$moment(pax.passportTerm),
        bonusCard: pax.bonusCard,
        countryId: pax.countryId,
        firstName: pax.firstName,
        lastName: pax.lastName,
        patronymic: pax.patronymic,
        amounts: JSON.parse(pax.amounts || '{}')
      }

      this.loadCountryList()
    },

    async loadCountryList() {
      if (this.countryList.length) return

      this.editPaxDialog.loading = true

      try {
        const res = await this.graphql('aviaBookingItemCountryList', {}, 'query')

        this.countryList = JSON.parse(res)
      } catch (e) {
        this.$message({ type: 'error', message: e })
      } finally {
        this.editPaxDialog.loading = undefined
      }
    },

    async savePaxDialogForm() {
      this.editPaxDialog.loading = true

      try {
        const query = {
          __args: {
            ...this.editPaxDialog.form,
            birthDay: this.editPaxDialog.form.birthDay.toString(),
            passportTerm: this.editPaxDialog.form.passportTerm.toString(),
            amounts: JSON.stringify(this.editPaxDialog.form.amounts)
          }
        }

        await this.graphql('aviaBookingItemSavePassenger', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Data successfully saved',
          duration: 2600
        })

        this.editPaxDialog.visible = false

        this.reloadBooking(this.booking.id)
      } catch (e) {
        this.$message({ type: 'error', message: e })
      } finally {
        this.editPaxDialog.loading = undefined
      }
    }
  }
}
</script>

<style lang="scss">
.avia-booking-passengers-dialog {
  &__title {
    font-size: 18px;
    color: #256ccc;
    margin-bottom: 10px;
  }
  .el-dialog {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    &__header,
    &__body,
    &__footer {
      padding-left: 30px;
      padding-right: 30px;
    }
    &__header,
    &__footer {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &__title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .el-form-item {
    margin-bottom: 4px;
  }
  .el-form-item__label {
    color: #334154;
    line-height: 32px;
  }
}
</style>
