<template lang="pug">
  component(
    :is="wrapperComponent"
    v-show="field.type !== 'hidden'"
    :prop="field.prop || field.name"
    v-bind="labelAttribs(field, fieldset)"
  )
    template(v-if="field.splitInput")
      el-row
        el-col(:span="20")
          el-select(
            v-if="!isReversed(field, fieldset)"
            multiple
            v-model="dataValue"
            :placeholder="translate(`form.placeholder.${field.name}`, translate(`form.placeholder.select`))"
            :ref="reverseRef(field, fieldset)"
            :disabled="isDisabledField(field, fieldset.legend)"
            :remote-method="data => remoteMethod(data, field, blockScope, dataValue, fieldset)"
            @change="changeFieldValue(field, $event)"
            v-on="$listeners"
            v-bind="attribs(field, fieldset)"
          )
            el-option(
              v-for="option in fieldOptions(field, blockScope, dataValue, fieldset)"
              :key="`${option.id}_${option.label}`"
              :label="field.translateOptions ? translate(`form.options.${field.name}.${option.label}`) : option.label"
              :value="option.id"
            )
          el-input(
            v-else
            v-model="dataValue"
            type="textarea"
            :disabled="isDisabledField(field, fieldset.legend)"
            @change="changeFieldValue(field, $event)"
            v-on="$listeners"
            v-bind="attribs(field, fieldset)"
          )
        el-col(:span="4")
          div(style="text-align:center")
            el-button(@click="reverseField(field, fieldset)" type="primary" icon="el-icon-refresh" plain)
            el-button(v-if="!isReversed(field, fieldset)" @click="expandSelect(field, fieldset)" type="success" icon="el-icon-notebook-2" plain)
    el-select(
      v-else
      multiple
      v-model="dataValue"
      :placeholder="translate(`form.placeholder.${field.name}`, translate(`form.placeholder.select`))"
      :ref="reverseRef(field, fieldset)"
      :remote-method="data => remoteMethod(data, field, blockScope, dataValue, fieldset)"
      :disabled="isDisabledField(field, fieldset.legend)"
      :multiple-limit="field.multipleLimit || 0"
      @change="changeFieldValue(field, $event)"
      v-on="$listeners"
      v-bind="attribs(field, fieldset)"
    )
      el-option(
        v-for="option in fieldOptions(field, blockScope, dataValue, fieldset)"
        :key="`${option.id}_${option.label}`"
        :label="field.translateOptions ? translate(`form.options.${field.name}.${option.label}`) : option.label"
        :value="option.id"
      )
        template(v-if="option.colorTag")
          el-tag(:class="option.colorTag" class="color-tag-for-select" mini)
          span &nbsp;&nbsp;&nbsp;{{ field.translateOptions ? translate(`form.options.${field.name}.${option.label}`) : option.label }}
</template>

<script>

import formElements from './../../../mixin/form/elements'

export default {
  name: 'TtnMultiselect',
  mixins: [formElements],
  methods: {
    defaultAttribs(field, fieldset) {
      return {
        clearable: true,
        filterable: true,
        'collapse-tags': field.collapseTags || false,
        style: 'width:100%',
        remote: field.lazy || false,
        rows: field.rows || 4
      }
    },

    reverseRef(field, fieldset) {
      return `reverse_${fieldset && fieldset.prop ? fieldset.prop : field.name}`
    }
  }
}
</script>
