import { getData } from '@/api/boot'

const state = {
  isLoaded: false,
  config_items: [],
  config_items_hash: {},
  traffic_sources: [],
  traffic_sources_hash: {},
  depots: [],
  depots_hash: {},
  avia_drivers: [],
  avia_drivers_hash: {},
  roles: [],
  legacyRoles: []
}

const mutations = {
  SET_CONFIG_ITEMS: (state, items) => {
    state.config_items = Object.freeze(Object.values(items))
    state.config_items_hash = items
  },
  SET_TRAFFIC_SOURCES: (state, items) => {
    state.traffic_sources = Object.freeze(Object.values(items))
    state.traffic_sources_hash = items
  },
  SET_DEPOTS: (state, items) => {
    state.depots = Object.freeze(Object.values(items))
    state.depots_hash = items
  },
  SET_AVIA_DRIVERS: (state, items) => {
    state.avia_drivers = Object.freeze(Object.values(items))
    state.avia_drivers_hash = items
  },
  SET_ROLES: (state, items) => {
    state.roles = items
  },
  SET_LEGACY_ROLES: (state, items) => {
    state.legacyRoles = items
  },
  SET_LOADED: (state, flag) => {
    state.isLoaded = flag
  }
}

const actions = {

  // get user info
  getData({ commit, state }) {
    return new Promise((resolve, reject) => {
      getData().then(response => {
        const { data } = response
        commit('SET_CONFIG_ITEMS', Object.freeze(data.bootingData.aviaConfigItems.reduce((acc, i) => {
          acc[i.id] = Object.freeze(i)
          return acc
        }, {})))
        commit('SET_TRAFFIC_SOURCES', Object.freeze(data.bootingData.trafficSources.reduce((acc, i) => {
          acc[i.id] = Object.freeze(i)
          return acc
        }, {})))
        commit('SET_DEPOTS', Object.freeze(data.bootingData.depots.reduce((acc, i) => {
          acc[i.id] = Object.freeze(i)
          return acc
        }, {})))
        commit('SET_AVIA_DRIVERS', Object.freeze(data.bootingData.aviaDrivers.reduce((acc, i) => {
          acc[i.id] = Object.freeze(i)
          return acc
        }, {})))
        commit('SET_ROLES', data.bootingData.roles)
        commit('SET_LEGACY_ROLES', data.bootingData.legacyRoles)
        commit('SET_LOADED', true)
        resolve(data)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },

  resetData({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_CONFIG_ITEMS', [])
      commit('SET_TRAFFIC_SOURCES', [])
      commit('SET_DEPOTS', [])
      commit('SET_AVIA_DRIVERS', [])
      commit('SET_ROLES', [])
      commit('SET_LEGACY_ROLES', [])
      commit('SET_LOADED', false)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
