<template lang="pug">
  extends /table
</template>

<script>
  import BaseTable from '@crud_view/table'

  export default {
    components: {
      BaseTable
    },
    extends: BaseTable,
    mounted() {
      this.$elItem.$onAction('refreshCombined', this.refreshCombined)
    },
    beforeDestroy() {
      this.$elItem.$offAction('refreshCombined', this.refreshCombined)
    },
    methods: {
      actionHistory(params) {
        const hItem = this.$elItem.$children.find(el => typeof(el.tabHistory) === 'string')
        if (hItem !== 'undefined') hItem.tabHistory = params.record_class
      },
      refreshCombined(row) {
        this.loadCombinedChildRecords(row)
      }
    }
  }
</script>