export default {
  mcos_list: {
    table: {
      id: 'ID',
      railwayBookingId: 'Booking ID',
      locator: 'Locator',
      orderId: 'Order ID',
      statusString: 'Status',
      recordTypeString: 'Record Type',
      requestString: 'Request',
      orderChangesString: 'Changes',
      penaltiesString: 'Penalties',
      paymentSystem: 'Payment System',
      tnxId: 'Tnx ID',
      transactionCommission: 'Transaction Commission',
      transactionTotal: 'Transaction Total',
      paymentDate: 'Payment Date',
      reasonString: 'Reason',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
      comment: 'Comment',
      user: 'User',
      date: 'Date'
    },
    panel: {
      transactions: 'Transactions',
      comments: 'Comments'
    },
    form: {
      fields: {
        id: 'ID',
        railwayBookingId: 'Booking ID',
        orderId: 'Order ID',
        tnxId: 'Tnx ID'
      }
    }
  }
}