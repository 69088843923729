export default {
  create(ctx) {
    return {
      legend: 'general',
      translateLegend: true,
      items: [
        {
          name: 'id',
          type: 'hidden',
          required: false,
          valueType: 'integer'
        },
        {
          name: 'hotPiesMarketId',
          type: 'hidden',
          required: false,
          valueType: 'integer'
        },
        {
          name: 'name',
          type: 'text',
          required: true
        },
        {
          name: 'departure',
          type: 'text',
          required: true
        },
        {
          name: 'arrival',
          type: 'text',
          required: true
        },
        {
          name: 'active',
          type: 'switch',
          default: false,
          required: false
        }
      ]
    }
  }
}