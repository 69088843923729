export default {
  create(ctx) {
    return {
      name: `hot_pies_${ctx.namespace}_markets`,
      endpoint: '/persistance/default',
      type: 'graphql',
      graphql: this.dcBuilder.create('markets/graphql', ctx),
      table: this.dcBuilder.create('markets/table', ctx),
      actions: this.dcBuilder.create('markets/actions', ctx),
      filter: this.dcBuilder.create('markets/filter', ctx),
      form: this.dcBuilder.create('markets/form', ctx),
      stores: this.dcBuilder.create('markets/store', ctx),
      combined: this.dcBuilder.create('markets/combined', ctx),
      collapsibleCombine: true
    }
  }
}