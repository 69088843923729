<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:reservationId="scope")
      div {{ scope.row['reservationId'] }} ({{ scope.row['providerName'] }})
      strong {{ scope.row['bookingId'] }}
    template(v-slot:user="scope")
      template(v-if="scope.row['user']")
        div {{ scope.row['user']['username'] }} ({{ scope.row['user']['id'] }})
        div {{ scope.row['user']['email'] }}
      div {{ scope.row['ip'] }}
    template(v-slot:totalCost="scope")
      strong {{ scope.row['totalCost'] }}
      span &nbsp;{{ scope.row['currency'] }}
      div {{ scope.row['depotName'] }}
      div {{ scope.row['trafficSourceName'] }}
      div {{ scope.row['nodeName'] }}
    template(v-slot:route="scope")
      div {{ scope.row['train'] }}
      div
        strong {{ scope.row['route'] }}
    template(v-slot:createdAt="scope")
      div {{ scope.row['createdAt'] }}
      div {{ scope.row['cancelledAt'] }}
    template(v-slot:expirationTime="scope")
      div {{ scope.row['bookingStatus'] }}
      strong {{ scope.row['redemptionDate'] }}
    template(v-slot:comment="scope")
      div(v-html="scope.row['comment']")
  block expand
    div
      div
        div
          el-divider {{ translate('table.trip_info') }}
          el-table(border style="width: 100%" :data="[scope.row]" header-cell-class-name="railway-booking-header")
            el-table-column(:label="translate('table.ticket')")
              template(slot-scope="scope")
                strong {{ scope.row["bookingId"] || '-' }}
            el-table-column(prop="sessionId" :label="translate('table.session_id')")
          el-table(border style="width: 100%" :data="[scope.row]" header-cell-class-name="railway-booking-header")
            el-table-column(:label="translate('table.train')")
              template(slot-scope="scope") {{ scope.row['departureStation'] }} &rarr; {{ scope.row['arrivalStation'] }} ({{ scope.row['trainNumber'] }})
            el-table-column(:label="translate('table.route2')")
              template(slot-scope="scope") {{ scope.row['passengerDepartureStation'] }} &rarr; {{ scope.row['passengerArrivalStation'] }}
            el-table-column(:label="translate('table.departure')")
              template(slot-scope="scope") {{ scope.row['departureDate'] }} {{ scope.row['departureTime'] }}
            el-table-column(:label="translate('table.arrival')")
              template(slot-scope="scope") {{ scope.row['arrivalDate']}} {{ scope.row['arrivalTime']}}
            el-table-column(prop="carType" :label="translate('table.carriage')")
              template(slot-scope="car") №{{ car.row['carNumber'] }}, {{ translate('table.class') }}: {{ car.row['carClass'] }}, {{ translate('table.type') }}: {{ translate(`booking.carType.${car.row['carType']}`) }}
            el-table-column(:label="translate('table.bedclothes')")
              template(slot-scope="scope") {{ scope.row['bedclothes'] ? translate('table.yes') : translate('table.no') }}
          template(v-if="scope.row['insuranceLocator']")
            el-divider {{ translate('table.insurance') }}
            el-table(border style="width: 100%" :data="[scope.row]" header-cell-class-name="railway-booking-header")
              el-table-column(prop="insuranceLocator" :label="translate('table.locator')" :formatter="emptyFormatter")

          el-divider {{ translate('table.passenger_info') }}

          el-table(border style="width: 100%" :data="scope.row['railwayBookingDocuments']" header-cell-class-name="railway-booking-header")
            el-table-column(type="index")
            el-table-column(prop="salutation" :label="translate('table.salutation')" :formatter="emptyFormatter")
            el-table-column(prop="firstName" :label="translate('table.first_name')" :formatter="emptyFormatter")
            el-table-column(prop="lastName" :label="translate('table.last_name')" :formatter="emptyFormatter")
            el-table-column(prop="patronymic" :label="translate('table.patronymic')" :formatter="emptyFormatter")
            el-table-column(prop="nationality" :label="translate('table.nationality')" :formatter="emptyFormatter")
            el-table-column(prop="passengerDocType" :label="translate('table.doc_type')" :formatter="emptyFormatter")
            el-table-column(prop="passengerDocNumber" :label="translate('table.doc_number')" :formatter="emptyFormatter")
            el-table-column(prop="birthday" :label="translate('table.birthday')" :formatter="emptyFormatter")
            el-table-column(prop="seatNumber" :label="translate('table.seat')" :formatter="emptyFormatter")
            el-table-column(:label="translate('table.type')")
              template(slot-scope="passenger") {{ translate(passenger.row['docType'] == 1 ? 'table.adult' : (passenger.row['docType'] == 6 ? 'table.student' : 'table.child')) }}
            el-table-column(prop="docCost" :label="translate('table.cost')")
            el-table-column(:label="translate('table.refund_status')")
              template(slot-scope="refundStatus") {{ translate(`table.refund_statuses.${refundStatus.row['refundStatus']}`) }}
            el-table-column(prop="refundAmount" :label="translate('table.refund_amount')" :formatter="emptyFormatter")
            el-table-column(prop="refundDate" :label="translate('table.refund_date')" :formatter="emptyFormatter")
            el-table-column(prop="refundCommission" :label="translate('table.refund_commission')" :formatter="emptyFormatter")
            el-table-column(prop="bookingId" :label="translate('table.booking_id')" :formatter="emptyFormatter")
            el-table-column(prop="fiscalNumber" :label="translate('table.fiscal_id')" :formatter="emptyFormatter")
            el-table-column(type="expand")
              template(slot-scope="services")
                el-divider {{ translate('table.services') }}
                el-table(border style="width: 100%" :data="services.row['railwayBookingDocumentServices']" header-cell-class-name="railway-booking-header")
                  el-table-column(type="index")
                  el-table-column(:label="translate('table.service')")
                    template(slot-scope="srv") {{ translate(`booking.document_services.${srv.row['service']}`) }}
                  el-table-column(prop="cost" label="Cost")
                el-divider {{ translate('table.baggage') }}
                el-table(border style="width: 100%" :data="services.row['railwayBookingExtraDocuments']" header-cell-class-name="railway-booking-header")
                  el-table-column(type="index")
                  el-table-column(label="Type")
                    template(slot-scope="luggage") {{ translate(`booking.baggage.${luggage.row['luggageType']}`) }}
                  el-table-column(prop="luggageWeight" :label="translate('table.weight')")
                  el-table-column(prop="luggageCost" :label="translate('table.cost')")
                  el-table-column(prop="originalCost" :label="translate('table.original_cost')")
                  el-table-column(prop="fullCost" :label="translate('table.full_cost')")
                  el-table-column(prop="luggageVat" :label="translate('table.vat')")
                  el-table-column(prop="luggageServiceCost" :label="translate('table.service_cost')")
                  el-table-column(prop="uid" :label="translate('table.uid')")
                  el-table-column(prop="reservationId" :label="translate('table.reservation_id')")
                  el-table-column(prop="bookingId" :label="translate('table.booking_id')")
                  el-table-column(prop="fiscalNumber" :label="translate('table.fiscal_id')")
                  el-table-column(prop="refundStatus" :label="translate('table.refund_status')")
                  el-table-column(prop="refundAmount" :label="translate('table.refund_amount')")
                  el-table-column(prop="refundDate" :label="translate('table.refund_date')")


          template(v-if="scope.row['railwayBookingSegments'].length")
            el-divider {{ translate('table.segments') }}
            el-table(border style="width: 100%" :data="scope.row['railwayBookingSegments']" header-cell-class-name="railway-booking-header")
              el-table-column(type="index")
              el-table-column(prop="trainNumber" :label="translate('table.train_number')")
              el-table-column(:label="translate('table.route2')")
                template(slot-scope="scope") {{ scope.row['departureStation'] }} &rarr; {{ scope.row['arrivalStation'] }}
              el-table-column(:label="translate('table.departure')")
                template(slot-scope="seg") {{ seg.row['departureDate'] }} {{ seg.row['departureTime'] }}
              el-table-column(:label="translate('table.arrival')")
                template(slot-scope="seg") {{ seg.row['arrivalDate'] }} {{ seg.row['arrivalTime'] }}
              el-table-column(prop="travelTime" :label="translate('table.travel_time')")
              el-table-column(prop="carNumber" :label="translate('table.car_number')")
              el-table-column(prop="seats" :label="translate('table.seats')")
              el-table-column(prop="segmentType" :label="translate('table.segment_type')")
              el-table-column(prop="addlInfoJson" :label="translate('table.addl_info_json')")
              


          template(v-if="scope.row['railwayBookingUpsales'].length")
            el-divider {{ translate('table.upsales') }}
            el-table(border style="width: 100%" :data="scope.row['railwayBookingUpsales']" header-cell-class-name="railway-booking-header")
              el-table-column(type="index")
              el-table-column(prop="name" :label="translate('table.name')")
              el-table-column(prop="cost" :label="translate('table.cost')")
              el-table-column(prop="currency" :label="translate('table.currency')")

          template(v-if="scope.row['pricerRules']")
            el-divider {{ translate('table.pricer_rules') }}
            pre(v-html="scope.row['pricerRules']")



          el-divider {{ translate('table.payments') }}
          el-table(border style="width: 100%" :data="[scope.row]" header-cell-class-name="railway-booking-header")
            el-table-column(prop="cost" :label="translate('table.cost')")
            el-table-column(prop="serviceCost" :label="translate('table.service_cost')")
            el-table-column(prop="acquiringService" :label="translate('table.acquiring')")
            el-table-column(prop="transactionCommission" :label="translate('table.transaction_commission')")
            el-table-column(prop="schemeService" :label="translate('table.scheme_service')")
            el-table-column(prop="defaultSchemeService" :label="translate('table.default_scheme_service')")
            el-table-column(prop="defaultService" :label="translate('table.default_service')")
            el-table-column(prop="bonus" :label="translate('table.bonus')")
            el-table-column(prop="promotionDiscount" :label="translate('table.promotion')")
            el-table-column(prop="totalCost" :label="translate('table.total_cost')")

  block custom
    el-drawer(
      :title="drawer.title"
      :visible.sync="drawer.visible"
      direction="ttb")
      el-table(border style="width: 100%" v-if="drawer.type == 'comments'" :data="drawer.comments" header-cell-class-name="railway-booking-header" v-loading="drawer.loading" element-loading-text="Loading...")
        el-table-column(prop="createdAt" :label="translate('table.date')")
        el-table-column(prop="backendUserName" :label="translate('table.user')")
        el-table-column(:label="translate('table.comment')")
          template(slot-scope="scope")
            span(v-html="scope.row.data")

      el-timeline(v-if="drawer.type == 'transactions'" v-loading="drawer.loading" element-loading-text="Loading...")
        el-timeline-item(
          v-for="(tnx, index) in drawer.transactions"
          :key="index"
          :type="transactionType(tnx.status)"
          :timestamp="tnx.createdAt"
        )
          el-descriptions(:title="`ID: ${tnx.id}`")
            el-descriptions-item(:label="translate('table.payer')") {{tnx.payer}}
            el-descriptions-item(:label="translate('table.booking_id')") {{tnx.bookingId}}
            el-descriptions-item(:label="translate('table.payment_system')") {{tnx.paymentSystemName}} ({{tnx.paymentSystem}})
            el-descriptions-item(:label="translate('table.transaction_id')") {{tnx.transactionId}}
            el-descriptions-item(:label="translate('table.amount')") {{tnx.amount}}
            el-descriptions-item(:label="translate('table.system_id')") {{tnx.systemId}}
            el-descriptions-item(:label="translate('table.status')") {{tnx.status}}
            el-descriptions-item(:label="translate('table.total')") {{tnx.total}}
            el-descriptions-item(:label="translate('table.currency')") {{tnx.currency}}
            el-descriptions-item(:label="translate('table.user_id')") {{tnx.userId}}
            el-descriptions-item(:label="translate('table.card')") {{tnx.pan6}}******{{tnx.pan4}}
    el-dialog(:visible.sync="centerDialogVisible" center title="Confirm Booking")
      el-row(:gutter="20")
        el-col
          el-input(type="textarea" v-model="confirmationData" :autosize="{ minRows: 10, maxRows: 25 }")
      el-row(:gutter="20")
        el-col &nbsp;
      span.dialog-footer
        el-button(@click="confirmBookingData" type="primary") Confirm
        el-button(slot="footer" @click="centerDialogVisible = false") Close
    el-dialog(:visible.sync="commentsDialogVisible" center title="Add Booking Comment")
      el-row(:gutter="20")
        el-col
          el-input(type="textarea" v-model="comment" :autosize="{ minRows: 10, maxRows: 25 }")
      el-row(:gutter="20")
        el-col &nbsp;
      span.dialog-footer
        el-button(@click="addComment" type="primary") Add Comment
        el-button(slot="footer" @click="commentsDialogVisible = false") Close
</template>

<script>

import BaseTable from '@crud_view/table'
import di from '@crud_lib/di'
export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  mixins: [di],
  data() {
    return {
      enabled: [],
      centerDialogVisible: false,
      commentsDialogVisible: false,
      confirmationData: '',
      comment: '',
      drawer: {
        type: 'none',
        visible: false,
        loading: false,
        title: '',
        transactions: [],
        comments: []
      }
    }
  },
  mounted() {
    this.$elItem.$onAction('regenerateFile', this.regenerate)
    this.$elItem.$onAction('download', this.download)
    this.$elItem.$onAction('removeParentId', this.removeParentId)
    this.$elItem.$onAction('removeChildId', this.removeChildId)
    this.$elItem.$onAction('showTransactions', this.showTransactions)
    this.$elItem.$onAction('showComments', this.showComments)
    this.$elItem.$onAction('changeStatus', this.changeStatus)
    this.$elItem.$onAction('confirmBooking', this.confirmBooking)
    this.$elItem.$onAction('autoConfirmBooking', this.autoConfirmBooking)
    this.$elItem.$onAction('bookingStatus', this.bookingStatus)
    this.$elItem.$onAction('addCommentHandler', this.addCommentHandler)
  },
  beforeDestroy() {
    this.$elItem.$offAction('regenerateFile', this.regenerate)
    this.$elItem.$offAction('download', this.download)
    this.$elItem.$offAction('removeParentId', this.removeParentId)
    this.$elItem.$offAction('removeChildId', this.removeChildId)
    this.$elItem.$offAction('showTransactions', this.showTransactions)
    this.$elItem.$offAction('showComments', this.showComments)
    this.$elItem.$offAction('changeStatus', this.changeStatus)
    this.$elItem.$offAction('confirmBooking', this.confirmBooking)
    this.$elItem.$offAction('autoConfirmBooking', this.autoConfirmBooking)
    this.$elItem.$offAction('bookingStatus', this.bookingStatus)
    this.$elItem.$offAction('addCommentHandler', this.addCommentHandler)
  },
  methods: {
    cellClick(row, _column, _event) {
      this.$children[0].toggleRowSelection(row)
    },
    confirmBookingData() {
      if (this.confirmationData.length === 0) {
        return
      } else {
        this.proceedBookingConfirmation()
      }
    },
    async autoConfirmBooking() {
      try {
        const query = {
          __args: {
            id: this.multipleSelection.map((i) => { return parseInt(i.id) })[0]
          }
        }
        await this.graphql('railwayBookingAutoConfirmDatum', query, 'mutation')

        this.$notify({
          title: 'Confirm',
          message: 'Trying...',
          duration: 2600
        })
      } catch (e) {
        console.error(e)
      }
    },
    async proceedBookingConfirmation() {
      try {
        const query = {
          __args: {
            id: this.multipleSelection.map((i) => { return parseInt(i.id) })[0],
            data: this.confirmationData
          }
        }
        await this.graphql('railwayBookingConfirmDatum', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'File saved...',
          duration: 2600
        })

        this.confirmationData = ''
        this.centerDialogVisible = false
      } catch (e) {
        console.error(e)
      }
    },
    transactionType(status)
    {
      switch(status) {
        case 'success':
          return 'success'
        case 'failure':
          return 'danger'
        case 'created':
          return 'info'
        case 'lock':
          return 'warning'
        default:
          return 'info'
      }
    },
    async showTransactions()
    {
      this.drawer.title = this.$elItem.translate('table.payment_transactions')
      this.drawer.type = 'transactions'
      this.drawer.visible = true
      this.drawer.loading = true
      try {
        const query = {
          __args: {
            id: this.multipleSelection.map((i) => { return parseInt(i.id) })[0]
          },
          collection: {
            id: true,
            payer: true,
            bookingId: true,
            createdAt: true,
            paymentSystem: true,
            transactionId: true,
            amount: true,
            systemId: true,
            status: true,
            total: true,
            currency: true,
            userId: true,
            commission: true,
            pan4: true,
            pan6: true,
            paymentSystemName: true
          }
        }

        let res = await this.graphql('railwayBookingPaymentTransaction', query, 'query')
        this.drawer.transactions = res.collection
        this.drawer.loading = false
      } catch (e) {
        this.drawer.visible = false
        this.drawer.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },
    async showComments()
    {
      this.drawer.title = this.$elItem.translate('table.comments')
      this.drawer.type = 'comments'
      this.drawer.visible = true
      this.drawer.loading = true
      try {
        const query = {
          __args: {
            id: this.multipleSelection.map((i) => { return parseInt(i.id) })[0]
          },
          collection: {
            id: true,
            data: true,
            createdAt: true,
            backendUserId: true,
            backendUserName: true
          }
        }

        let res = await this.graphql('railwayBookingComment', query, 'query')
        this.drawer.comments = res.collection
        this.drawer.loading = false
      } catch (e) {
        this.drawer.visible = false
        this.drawer.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },
    async changeStatus(status) {
      try {
        const query = {
          __args: {
            id: this.multipleSelection.map((i) => { return parseInt(i.id) })[0],
            status: parseInt(status)
          }
        }

        await this.graphql('railwayBookingChangeStatus', query, 'mutation')

        this.$message({
          message: 'Success!',
          type: 'success'
        })
        this.parentItemComponent().$emitAction('refresh')
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },

    addCommentHandler()
    {
      this.commentsDialogVisible = true
      this.comment = ''
    },
    addComment()
    {
      if (this.comment.length === 0) {
        return
      } else {
        this.proceedComment()
      }
    },
    async proceedComment() {
      try {
        const query = {
          __args: {
            id: this.multipleSelection.map((i) => { return parseInt(i.id) })[0],
            comment: this.comment
          }
        }

        await this.graphql('railwayBookingAddComment', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Сomment added',
          duration: 2600
        })

        this.comment = ''
        this.commentsDialogVisible = false
        this.parentItemComponent().$emitAction('refresh')
      } catch (e) {
        console.error(e)
      }
    },
    async bookingStatus(status) {
      try {
        const query = {
          __args: {
            id: this.multipleSelection.map((i) => { return parseInt(i.id) })[0]
          }
        }

        let res = await this.graphql('railwayBookingBookingStatus', query, 'mutation')

        this.$alert(res, 'Booking Status', {
          confirmButtonText: 'OK'
        })
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },
    async download()
    {
      try {
        const query = {
          __args: {
            ids: this.multipleSelection.map((i) => { return parseInt(i.id) })
          }
        }

        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
          const byteCharacters = atob(b64Data)
          const byteArrays = []

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize)

            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)
            byteArrays.push(byteArray)
          }

          const blob = new Blob(byteArrays, { type: contentType })
          return blob
        }

        let res = await this.graphql('railwayBookingDownload', query, 'mutation')
        const blob = b64toBlob(res, 'application/pdf')

        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `${query.__args.ids[0]}.pdf`
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },
    confirmBooking() {
      this.centerDialogVisible = true
      this.confirmationData = ''
    },
    async regenerate()
    {
      try {
        const query = {
          __args: {
            ids: this.multipleSelection.map((i) => { return parseInt(i.id) })
          }
        }

        await this.graphql('railwayBookingRegenerate', query, 'mutation')

        this.$message({
          message: 'Success!',
          type: 'success'
        })
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },
    async removeParentId()
    {
      try {
        const query = {
          __args: {
            ids: this.multipleSelection.map((i) => { return parseInt(i.id) })
          }
        }

        await this.graphql('railwayBookingRemoveParentId', query, 'mutation')

        this.$message({
          message: 'Success!',
          type: 'success'
        })
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },
    async removeChildId()
    {
      try {
        const query = {
          __args: {
            ids: this.multipleSelection.map((i) => { return parseInt(i.id) })
          }
        }

        await this.graphql('railwayBookingRemoveChildId', query, 'mutation')

        this.$message({
          message: 'Success!',
          type: 'success'
        })
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },
    rowClassName({ row, _rowIndex })
    {
      if ([0, 1, 3, 4, 7, 8, 10, 11, 21, 22].includes(row.status))
      {
        return 'class-' + row.status
      } else {
        return ''
      }
    },
    emptyFormatter(_row, _column, v, _) {
      return v || '-'
    }
  }
}
</script>
<style lang="scss">
  .el-table--striped .el-table__body tr.el-table__row--striped.class-0 td.el-table__cell, .el-table--enable-row-hover .el-table__body tr.class-0:hover > td.el-table__cell, .el-table tr.class-0 { background-color: #80FFFF; }
  .el-table--striped .el-table__body tr.el-table__row--striped.class-1 td.el-table__cell, .el-table--enable-row-hover .el-table__body tr.class-1:hover > td.el-table__cell, .el-table tr.class-1 { background-color: #9EFF7F; }
  .el-table--striped .el-table__body tr.el-table__row--striped.class-3 td.el-table__cell, .el-table--enable-row-hover .el-table__body tr.class-3:hover > td.el-table__cell, .el-table tr.class-3 { background-color: #F78181; }
  .el-table--striped .el-table__body tr.el-table__row--striped.class-4 td.el-table__cell, .el-table--enable-row-hover .el-table__body tr.class-4:hover > td.el-table__cell, .el-table tr.class-4 { background-color: #FF99CC; }
  .el-table--striped .el-table__body tr.el-table__row--striped.class-7 td.el-table__cell, .el-table--enable-row-hover .el-table__body tr.class-7:hover > td.el-table__cell,  .el-table tr.class-7 { background-color: #FFC039; }
  .el-table--striped .el-table__body tr.el-table__row--striped.class-8 td.el-table__cell, .el-table--enable-row-hover .el-table__body tr.class-8:hover > td.el-table__cell, .el-table tr.class-8 { background-color: #C0C0C0; }
  .el-table--striped .el-table__body tr.el-table__row--striped.class-21 td.el-table__cell, .el-table--enable-row-hover .el-table__body tr.class-21:hover > td.el-table__cell, .el-table tr.class-21 { background-color: #124537; color: #fff; }
  .el-table--striped .el-table__body tr.el-table__row--striped.class-22 td.el-table__cell, .el-table--enable-row-hover .el-table__body tr.class-22:hover > td.el-table__cell, .el-table tr.class-22 { background-color: #7e1a0d; color: #fff; }
  .el-table--striped .el-table__body tr.el-table__row--striped.class-10  td.el-table__cell, .el-table--enable-row-hover .el-table__body tr.class-10:hover > td.el-table__cell, .el-table tr.class-10 { background-color: #FE9A2E; }
  .el-table--striped .el-table__body tr.el-table__row--striped.class-11 td.el-table__cell, .el-table--enable-row-hover .el-table__body tr.class-11:hover > td.el-table__cell, .el-table tr.class-11 { background-color: #fff700; }

  .railway-booking-header {
    background-color: #B3C0D1 !important;
    color: #333;
    text-align: center;
  }
</style>