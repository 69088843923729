export default {
  create(ctx) {
  	return {
      fields: {
        depotId: 'Depot',
        active: 'Active',
        settings__limit: 'Limit',
        settings__apiKey: 'API Key',
        settings__popularDateRange: 'Popular Date Range',
        settings__popularDateShift: 'Popular Date Shift'
      },
      legends: {
        general: 'General',
        settings: 'Settings'
      },
      placeholder: {
        settings__limit: 'Routes total count (manual + popular). Example: 10',
        settings__apiKey: 'Node API Key',
        settings__popularDateShift: 'Search date (today + VALUE days). Example: 1'
      }
  	}
  }
}