
export default {
  name: 'reports',
  items: [
    {
      name: 'reports_list',
      endpoint: '/persistance/default',
      pagination: true,
      autoLoad: true,
      type: 'graphql',
      graphql: {
        name: 'radarReports',
        queries: {
          index: {
            id: true,
            radarSettingId: true,
            bid: true,
            status: true,
            process: true,
            createdAt: true,
            updatedAt: true,
            setting: {
              id: true,
              name: true,
              currency: true
            },
          }
        },
        actions: {
          download: 'radarReportDownload'
        },
        preloadFields: ['setting{id name}']
      },
      table: {
        class: 'radar-reports-table',
        items: [
          'selection',
          { 
            name: 'id',
            width: '50px',
            sortable: false
          },
          {
            name: 'setting.name',
            width: '275px',
            sortable: false
          },
          {
            name: 'bid',
            sortable: false
          },
          {
            name: 'createdAt',
            sortable: false,
            width: '200px'
          },
          {
            name: 'status',
            type: 'tag',
            tagType: 'success',
            width: '200px',
            sortable: false,
          },
          {
            name: 'actions',
            width: '75px'
          }
        ]
      },
      actions: {
        row: [
          {
            name: 'download',
            icon: 'el-icon-download',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'delete',
            icon: 'el-icon-delete',
            type: 'danger',
            plain: true,
            dependent: 'selection'
          },
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true,
            params: {
              record_class: 'Radar::ReportRelation'
            }
          }
        ]
      },
      form: {}
    }
  ]
}
