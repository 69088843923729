export default {
  create(ctx) {
  	return {
      [`hot_pies_${ctx.namespace}_markets`]: {
        table: this.dcBuilder.create('markets/table', ctx),
        form: this.dcBuilder.create('markets/form', ctx),
        action: this.dcBuilder.create('markets/actions', ctx),
        combine_titles: this.dcBuilder.create('markets/combine_titles', ctx)

      },
      [`hot_pies_${ctx.namespace}_market_routes`]: {
        table: this.dcBuilder.create('routes/table', ctx),
        form: this.dcBuilder.create('routes/form', ctx),
      }
    }
  }
}