import { loadCrudModules } from '@/utils'

export default {
  title: 'Back Office System',

  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: false,

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {boolean} true | false
   * @description Whether support pinyin search in headerSearch
   * Bundle size minified 47.3kb,minified + gzipped 63kb
   */
  supportPinyinSearch: false,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: ['production'],

  boot: {
    enpoint: '/persistance/default',
    query: {
      bootingData: {
        aviaConfigItems: {
          id: true,
          name: true,
          isTargetingSearch: true
        },
        trafficSources: {
          id: true,
          name: true
        },
        depots: {
          id: true,
          name: true
        },
        aviaDrivers: {
          id: true,
          name: true
        },
        roles: true,
        legacyRoles: true
      }
    }
  },

  login: {
    endpoint: '/persistance/default',
    type: 'graphql'
  },

  modules: loadCrudModules(require.context('./modules', false, /\.js$/))
}
