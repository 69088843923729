import markupAnalysis from './system_tools/avia/markup_analysis'
import thoughtsOfSales from './system_tools/avia/thoughts_of_sales'
import conversionAnalysis from './system_tools/avia/conversion_analysis'
import iataParser from './system_tools/avia_parser/iata_parser'
import radarSettings from './system_tools/radar/settings'
import radarAviaConfigGroups from './system_tools/radar/avia_config_groups'
import radarReports from './system_tools/radar/reports'

export default {
  system_tools: {
    avia: {
      thoughts_of_sales: thoughtsOfSales,
      markup_analysis: markupAnalysis,
      conversion_analysis: conversionAnalysis
    },
    avia_parsers: {
      iata_parser: iataParser
    },
    radar: {
      settings: radarSettings,
      avia_config_groups: radarAviaConfigGroups,
      reports: radarReports
    }
  }
}
