<template lang="pug">
  extends /form

  block custom
    el-upload(
      :multiple="false"
      :limit="1"
      :auto-upload="false"
      action=""
      accept=".xlsx, .xls"
      :on-change="handleFile"
    )
      el-button(
        size="small"
        plain
        type="primary") {{ translate('table.select_file') }}
</template>

<script>
import BaseForm from '@crud_view/form'
import XLSX from 'xlsx'

export default {
  components: {
    BaseForm
  },
  extends: BaseForm,
  mixins: [],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    handleFile(e) {
      this.readerData(e.raw)
    },

    readerData(rawFile) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, { type: 'array' })
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const results = XLSX.utils.sheet_to_json(worksheet)
          this.parseResult(results)
          this.loading = false
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },

    parseResult(results) {
      this.temp['routes'] = results.map((x) => this._.values(x))
    }
  }
}
</script>
