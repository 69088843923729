<template lang="pug">
  el-table(:data="items" border)
  
    el-table-column(v-if="shouldShowExpandColumn" type='expand')
      template( #default="props")
        expand-table( v-if="props.row.group_items" :items="props.row.group_items" :group="childGroupName(props.row.group_items[0])")

    el-table-column(prop='group_value' :label="group" sortable width='200')
    el-table-column(label='Error' align='center')
      el-table-tooltiped-column(prop='e_ticketed' label='Ticketed' tooltipProp="e_ticketed_locators")
      el-table-tooltiped-column(prop='e_no_tkt' label='No tkt' tooltipProp="e_no_tkt_locators")
      el-table-column(prop='e_agent_saved_percent' label='% Agent saved' sortable)
        template(slot-scope="scope")
          div(:style="getBackgroundStyle(scope.row.e_agent_saved_percent, true)") {{scope.row.e_agent_saved_percent}}%
    el-table-column(label='No error' align='center')
      el-table-tooltiped-column(prop='ne_ticketed' label='Ticketed' tooltipProp="ne_ticketed_locators")
      el-table-tooltiped-column(prop='ne_no_tkt' label='No tkt' tooltipProp="ne_no_tkt_locators")
      el-table-column(prop='ne_ticketed_percent' label='% Ticketed' sortable)
        template(slot-scope="scope")
          div(:style="getBackgroundStyle(scope.row.ne_ticketed_percent, true)") {{scope.row.ne_ticketed_percent}}%
    el-table-column(prop='total' label='Total' align='right' sortable)
    el-table-column(prop='errors_percent' label='% Errors' sortable)
      template(slot-scope="scope")
        div(:style="getBackgroundStyle(scope.row.errors_percent)") {{scope.row.errors_percent}}%
    el-table-column(prop='ttl_issue_percent' label='% ttl issue' sortable)
      template(slot-scope="scope")
        div(:style="getBackgroundStyle(scope.row.ttl_issue_percent, true)") {{scope.row.ttl_issue_percent}}%
  
</template>

<script>
  import ElTableTooltipedColumn from './el_table_tooltiped_column';
  export default {
    name: "expand-table",
    components: { ElTableTooltipedColumn },
    inject: ['getParentBackgroundStyle'],
    provide() {
      return {
        getParentBackgroundStyle: this.getBackgroundStyle,
      };
    },
    props: {
      items: null,
      group: null
    },
    computed: {
      shouldShowExpandColumn() {
        return this.items.some((row) => row.group_items && row.group_items.length > 0);
      },
      getChildGroup(){
        return this.items && this.items.length > 0 && this.items[0].group_type.toUpperCase()
      }
    },
    methods: {
      getBackgroundStyle(value, isReversed = false) {
        return this.getParentBackgroundStyle(value, isReversed)
      },
      childGroupName(item){
        return item.group_type.toUpperCase();
      }
    }
  }
</script>