<template lang="pug">
  el-table-column(
    ref="column"
    :label="translate(`table.${column.name}`)"
    :formatter="formatter"
    v-on="$listeners"
    v-bind="attribs"
  )
    template(slot-scope="scope")
      slot(:name="column.name" v-bind="slotAttribs(scope,column)")
        el-switch(v-if="column.type === 'switch'" v-model="scope.row[column.name]" disabled)
        el-checkbox(v-else-if="column.type === 'checkbox'" :value="scope.row[column.name]")
        el-tag(v-else-if="column.type === 'tag'" :type="column.tagType") {{ defaultRenderCell($createElement, scope) }}
        template(v-else-if="column.type === 'tagList'")
          el-tag(v-for="(value) in defaultRenderCell($createElement, scope).split(',')" style='margin: 2px' :type="column.tagType") {{ value }}
        template(v-else)
          div(v-if="column.readMore" class="read_more")
            read-more(
              :text="defaultRenderCell($createElement, scope)"
              :max-chars="column.readMoreChars || 25"
              :more-str="translate('read_more.more')"
              :less-str="translate('read_more.less')"
              link="#"
            )
          template(v-else) {{ defaultRenderCell($createElement, scope) }}

</template>

<script>

import { defaultRenderCell } from 'element-ui/packages/table/src/config.js'
import tableColumns from './../../../mixin/table/columns'
import formats from './../../../formats'

export default {
  name: 'TtnTableColumnData',
  mixins: [tableColumns],
  computed: {
    sortableColumnType() {
      return this.$table.sortableColumnType(this.column)
    }
  },
  methods: {
    attributes() {
      return {
        sortable: this.sortableColumnType,
        showOverflowTooltip: this.column.overflowTooltip || false
      }
    },

    defaultRenderCell,

    formatter(row, column, cellValue, index) {
      if(this.column.format) {
        if(formats[this.column.format]) {
          cellValue = formats[this.column.format](cellValue, this.column, this.$table)
        } else{
          console.log(''.concat('Field format not exist ', this.column.format, ' for ', this.column.name))
        }
      }
      const formatProps = {
        row,
        '$column': column,
        column: this.column,
        cellValue,
        index
      }
      cellValue = this.$table.formatCell(formatProps)
      if (this.column.default !== undefined && (
        cellValue === '' ||
        cellValue === null ||
        cellValue === undefined ||
        Array.isArray(cellValue) && cellValue.length === 0
      )) {
        cellValue = this.column.default
      }
      return cellValue
    }
  }
}
</script>
