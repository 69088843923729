<template lang="pug">
  extends /panel
  block right_prepend
    el-button(type="plain" :disabled="isDisabled()" @click="handleAction('showTransactions')") {{ translate('panel.transactions') }}
    span &nbsp;
    el-button(type="plain" :disabled="isDisabled()" @click="handleAction('showComments')") {{ translate('panel.comments') }}
    span &nbsp;
</template>

<script>
import BasePanel from '@crud_view/panel'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  extends: BasePanel,
  mixins: [common, di],
  methods: {
    handleAction(actionName) {
      this.$elItem.$emitAction(actionName)
    },
    eventSelectionChange(val) {
      this.enabled = [val]
    },
    isDisabled() {
      return !this.enabled.includes(true)
    }
  }
}
</script>