export default {
  avia_config_group_list: {
    table: {
      id: 'ID',
      name: 'Name',
      aviaConfigIds: 'Configs'
    },
    form: {
      fields: {
        name: 'Name',
        aviaConfigIds: 'Configs'
      }    
    }
  }
}