<template lang="pug">
  extends /table
</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,

  data() {
    return {}
  },

  methods: {
    async actionRun(row) {
      try {
        this.loading = true
        const query = {
          __args: { id: row.id }
        }
        await this.graphql(this.config.graphql.actions.run, query, 'mutation')
        this.$message({
          message: 'Report generation has been initiated!',
          type: 'success'
        })
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      }
      this.$nextTick(() => {
        this.loading = false
      })
    }
  }

}
</script>
