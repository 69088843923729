import routes from './route'
import avia from './modules/avia'
import core from './modules/core'
import backend from './modules/backend'
import frontend from './modules/frontend'
import mco from './modules/mco'
import orders from './modules/orders'
import payment from './modules/payment'
import travelRankings from './modules/travel_rankings'
import webScraper from './modules/web_scraper'
import systemTools from './modules/system_tools'
import railway from './modules/railway'
import insurance from './modules/insurance'
import ttnAviaVi from './modules/ttn_avia_vi'
import xrates from './modules/xrates'
import uniservice from './modules/uniservice'

export default {
  ...avia,
  ...core,
  ...backend,
  ...frontend,
  ...mco,
  ...orders,
  ...payment,
  ...travelRankings,
  ...webScraper,
  ...systemTools,
  ...routes,
  ...railway,
  ...insurance,
  ...ttnAviaVi,
  ...xrates,
  ...uniservice,
  crud: {
    empty_results: 'Empty :(',
    table: {
      id: 'Id',
      status: 'Status',
      title: 'Title',
      actions: 'Actions',
      createdAt: 'Created at',
      updatedAt: 'Updated at'
    },
    action: {
      update: 'Update row',
      filter: 'Filter',
      create: 'Create',
      delete: 'Delete',
      refresh: 'Refresh',
      history: 'History'
    },
    form: {
      required: 'This field is required',
      create: 'Create',
      edit: 'Edit',
      confirm: 'Confirm',
      cancel: 'Cancel',
      apply: 'Apply',
      clear: 'Clear',
      add: 'Add',
      fields: {
        title: 'Title',
        status: 'Status'
      },
      placeholder: {
        select: 'Select',
        datepicker: 'Select date and time'
      }
    },
    read_more: {
      more: 'More',
      less: 'Less'
    }
  },

  history: {
    time: 'Time',
    userLogin: 'User',
    operation: 'Operation',
    recordId: 'Record ID',
    changes: 'Changes',
    search: 'Search',
    reset: 'Clear',
    operations: {
      create: 'Create',
      update: 'Update',
      delete: 'Delete'
    },
    dialog: {
      title: 'History'
    }
  },

  alert: {
    update_record: 'Update record',
    create_record: 'Create new record',
    delete_confirm: 'Are you sure?',
    delete_record: 'Delete records',
    warning: 'Warning!',
    success: 'Success'
  },
  navbar: {
    dashboard: 'Dashboard',
    github: 'Github',
    logOut: 'Log Out',
    profile: 'Profile',
    theme: 'Theme',
    size: 'Global Size'
  },
  login: {
    title: 'Login Form',
    logIn: 'Login',
    login: 'login',
    password: 'Password',
    any: 'any',
    thirdparty: 'Or connect with',
    thirdpartyTips: 'Can not be simulated on local, so please combine you own business simulation! ! !'
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo'
  }
}
