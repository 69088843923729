export default {
  namespace: 'railway',
  component: 'mcos',
  meta: {
    title: 'railway.mcos',
    icon: 'el-icon-edit'
  },
  tabs: [
    {
      name: 'mcos',
      items: [
        {
          name: 'mcos_list',
          endpoint: '/persistance/default',
          pagination: true,
          type: 'graphql',
          graphql: {
            name: 'railwayMcos',
            exclude: ['actions', 'expand'],
            queries: {
              index: {
                id: true,
                railwayBookingId: true,
                locator: true,
                orderId: true,
                statusString: true,
                statusColor: true,
                recordTypeString: true,
                requestString: true,
                orderChangesString: true,
                penaltiesString: true,
                paymentSystem: true,
                tnxId: true,
                transactionCommission: true,
                transactionTotal: true,
                paymentDate: true,
                reasonString: true,
                createdAt: true,
                updatedAt: true
              }
            }
          },
          table: {
            items: [
              'selection',
              {
                name: 'id',
                width: '70px',
                align: 'right'
              },
              'railwayBookingId', 'locator', 'orderId',
              'statusString', 'recordTypeString', 'requestString', 'orderChangesString', 'penaltiesString', 'paymentSystem', 'tnxId', 'transactionCommission', 'transactionTotal', 'paymentDate', 'reasonString', 
              'createdAt', 'updatedAt'
            ]
          },
          actions: {
            row: [
              {
                name: 'update',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ],
            panel: [
              {
                name: 'create',
                icon: 'el-icon-plus',
                type: 'primary',
                plain: false
              },
              {
                name: 'refresh',
                icon: 'el-icon-refresh',
                type: 'primary',
                plain: true
              },
              {
                name: 'history',
                icon: 'el-icon-refresh',
                type: 'warning',
                plain: true,
                params: {
                  record_class: 'Legacy::Railway::RailSettingRelation'
                }
              },
              {
                name: 'filter',
                context: 'panel',
                icon: 'el-icon-help',
                plain: true
              }
            ]
          },
          filter: {
            items: [
              {
                name: 'id',
                operator: 'eq',
                type: 'text'
              },
              {
                name: 'railway_booking_id',
                type: 'text',
                operator: 'eq'
              },
              {
                name: 'order_id',
                type: 'text',
                operator: 'eq'
              },
              {
                name: 'tnx_id',
                type: 'text',
                operator: 'eq'
              }
            ]
          }

        }
      ]
    }
  ]
}