<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:statusString="scope")
      el-tag(size="mini" :type="scope.row['statusColor']") {{ scope.row['statusString'] }}
    template(v-slot:orderChangesString="scope")
      div(v-html="scope.row['orderChangesString']")
    template(v-slot:requestString="scope")
      div(v-html="scope.row['requestString']")
    template(v-slot:penaltiesString="scope")
      div(v-html="scope.row['penaltiesString']")
  block custom
    el-drawer(
      :title="drawer.title"
      :visible.sync="drawer.visible"
      direction="ttb")
      el-table(border style="width: 100%" v-if="drawer.type == 'comments'" :data="drawer.comments" header-cell-class-name="railway-booking-header" v-loading="drawer.loading" element-loading-text="Loading...")
        el-table-column(prop="createdAt" :label="translate('table.date')")
        el-table-column(prop="backendUserName" :label="translate('table.user')")
        el-table-column(:label="translate('table.comment')")
          template(slot-scope="scope")
            span(v-html="scope.row.data")

      el-timeline(v-if="drawer.type == 'transactions'" v-loading="drawer.loading" element-loading-text="Loading...")
        el-timeline-item(
          v-for="(tnx, index) in drawer.transactions"
          :key="index"
          :type="transactionType(tnx.status)"
          :timestamp="tnx.createdAt"
        )
          el-descriptions(:title="`ID: ${tnx.id}`")
            el-descriptions-item(:label="translate('table.payer')") {{tnx.payer}}
            el-descriptions-item(:label="translate('table.booking_id')") {{tnx.bookingId}}
            el-descriptions-item(:label="translate('table.payment_system')") {{tnx.paymentSystemName}} ({{tnx.paymentSystem}})
            el-descriptions-item(:label="translate('table.transaction_id')") {{tnx.transactionId}}
            el-descriptions-item(:label="translate('table.amount')") {{tnx.amount}}
            el-descriptions-item(:label="translate('table.system_id')") {{tnx.systemId}}
            el-descriptions-item(:label="translate('table.status')") {{tnx.status}}
            el-descriptions-item(:label="translate('table.total')") {{tnx.total}}
            el-descriptions-item(:label="translate('table.currency')") {{tnx.currency}}
            el-descriptions-item(:label="translate('table.user_id')") {{tnx.userId}}
            el-descriptions-item(:label="translate('table.card')") {{tnx.pan6}}******{{tnx.pan4}}
</template>
<script>
import BaseTable from '@crud_view/table'
import di from '@crud_lib/di'
export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  mixins: [di],
  data() {
    return {
      enabled: [],
      drawer: {
        type: 'none',
        visible: false,
        loading: false,
        title: '',
        transactions: [],
        comments: []
      }
    }
  },
  mounted() {
    this.$elItem.$onAction('showTransactions', this.showTransactions)
    this.$elItem.$onAction('showComments', this.showComments)
  },
  beforeDestroy() {
    this.$elItem.$offAction('showComments', this.showComments)
    this.$elItem.$offAction('changeStatus', this.changeStatus)
  },
  methods: {
    cellClick(row, _column, _event) {
      this.$children[0].toggleRowSelection(row)
    },
    async showTransactions()
    {
      this.drawer.title = this.$elItem.translate('table.payment_transactions')
      this.drawer.type = 'transactions'
      this.drawer.visible = true
      this.drawer.loading = true
      try {
        const query = {
          __args: {
            id: this.multipleSelection.map((i) => { return parseInt(i.id) })[0]
          },
          collection: {
            id: true,
            payer: true,
            bookingId: true,
            createdAt: true,
            paymentSystem: true,
            transactionId: true,
            amount: true,
            systemId: true,
            status: true,
            total: true,
            currency: true,
            userId: true,
            commission: true,
            pan4: true,
            pan6: true,
            paymentSystemName: true
          }
        }

        let res = await this.graphql('railwayMcoPaymentTransaction', query, 'query')
        this.drawer.transactions = res.collection
        this.drawer.loading = false
      } catch (e) {
        this.drawer.visible = false
        this.drawer.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }
    },
    async showComments()
    {
      this.drawer.title = this.$elItem.translate('table.comments')
      this.drawer.type = 'comments'
      this.drawer.visible = true
      this.drawer.loading = true
      try {
        const query = {
          __args: {
            id: this.multipleSelection.map((i) => { return parseInt(i.id) })[0]
          },
          collection: {
            id: true,
            data: true,
            createdAt: true,
            backendUserId: true,
            backendUserName: true
          }
        }

        let res = await this.graphql('railwayMcoComment', query, 'query')
        this.drawer.comments = res.collection
        this.drawer.loading = false
      } catch (e) {
        this.drawer.visible = false
        this.drawer.loading = false
        this.$message({
          message: e,
          type: 'error'
        })
      }
    }
  }
}
</script>