export default {
  create(ctx) {
    return {
      name: 'uniserviceHotPiesMarkets',
      queries: {
        index: {
          id: true,
          depotId: true,
          service: true,
          settings: true,
          active: true,
          createdAt: true,
          updatedAt: true
        },
        get: {
          id: true,
          service: true,
          depotId: true,
          active: true,
          settings: true
        }
      },
      baseParams: {
        index: {
          filters: {
            uniserviceHotPiesMarkets: [
              { field: 'service', operator: "eq", value: ctx.namespace }
            ]
          }
        }
      }
    }
  }
}