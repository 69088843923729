export default {
  create(ctx) {
    return {
      name: `hot_pies_${ctx.namespace}`,
      alwaysShow: true,
      items: [
        this.dcBuilder.create('markets/tab', ctx)
      ]
    }
  }
}