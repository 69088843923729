<template lang="pug">
  div(v-if="booking")
    table(v-if="upsales.length")
      thead
        tr
          th(v-for="(head, h) in ['Name', 'Seo', 'Price', 'Original price', 'As part of package']" :key="h") {{ head }}
      tbody
        tr(v-for="({ name, seo_name, price, ...upsale }, u) in upsales" :key="u" :class="{ marked: seo_name === 'direct_as_service' }")
          td {{ name }}
          td {{ seo_name }}
          td.nowrap {{ price }} {{ (booking || {}).depotCurrency }}
          td.nowrap {{ originalPrice(upsale) }}
          td(v-html="packageMsg(upsale)")

    div(v-if="highPassOrders.length" style="padding: 10px").bold HighPass Orders
    table(v-if="highPassOrders.length")
      thead
        tr
          th ID
          th Order
          th Name
          th Status
          th Airport
          th Time
          th Search (Issue) Price
          th Margin
          th Final price
          th Depot price
          th Diff
          th Terms
          th Actions
      tbody
        tr(v-for="(order, o) in highPassOrders" :key="o")
          td {{ order.id }}
          td {{ order.orderNumber }}
          td {{ order.name }}
          td {{ highPassStatuses[order.status] || order.status }}
          td {{ order.airport }} ({{ order.terminal }})
          td {{ order.time }}
          td {{ order.providerPrice }} ({{ order.actualPrice }}) {{ order.providerCurrency }}
          td {{ order.margin }} {{ order.providerCurrency }}
          td {{ order.payedPrice }} {{ order.providerCurrency }}
          td {{ order.price }} {{ order.currency }}
          td(v-html="diffWrap(order)") 
          td
            el-link(v-if="order.termsUrl.length > 0" :href="order.termsUrl" target="_blank" type="primary" style="font-size: 12px") Link
            span(v-else) -
          td

    div(v-if="brbOrder" style="padding: 10px").bold BRB Order
    table(v-if="brbOrder")
      thead
        tr
          th Product code
          th Service number
          th Base price
          th Depot price
          th Coverage
          th Passengers
          th Status code
          th Status description
          th
      tbody
        tr
          td {{ brbOrder.code }}
          td.nowrap {{ brbOrder.serviceNumber }}
          td.nowrap {{ brbOrder.basePrice }} {{ brbOrder.baseCurrency }}
          td.nowrap {{ brbOrder.depotPrice }} {{ brbOrder.depotCurrency }}
          td.nowrap {{ brbOrder.coverage }} {{ brbOrder.baseCurrency }}
          td {{ brbOrder.passengers.join(',') }}
          td {{ brbOrder.status }}
          td {{ brbStatuses[brbOrder.status] || brbOrder.status }}
          td.skip-hover
            el-button(disabled) Purchase
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],

  props: {
    booking: null
  },

  data() {
    return {
      highPassStatuses: {
        created: 'Created. Not issued',
        tarifficated: 'Tarifficated. Not issued',
        unconfirmed: 'Unconfirmed',
        overpriced: 'Price changed',
        canceled: 'Canceled',
        confirmed: 'Confirmed. Not issued',
        cancel_pending: 'Cancel pending',
        issued: 'Issued'
      },
      brbStatuses: {
        PS: 'Purchase successful',
        PF: 'Purchase failed',
        WP: 'Waiting for purchase',
        IV: 'Inactive'
      }
    }
  },

  computed: {
    upsales({ booking }) {
      const services = JSON.parse((booking || {}).additionalServices || '[]')
      const discount = JSON.parse((booking || {}).discountInfoJson || '{}')

      return services
        .concat(
          discount.direct_as_service && {
            name: 'Direct as service',
            seo_name: 'direct_as_service',
            price: discount.direct_as_service
          }
        )
        .filter(Boolean)
    },
    highPassOrders: ({ booking }) => (booking || {}).highPassOrders || [],
    brbOrder: ({ booking }) => (booking || {}).brbOrder
  },

  methods: {
    packageMsg({ extra_data: data }) {
      const { depotCurrency } = this.booking || {}
      const { package_part, se0_name, flexible_total } = JSON.parse(
        data || '{}'
      )

      if (package_part) return '<span style="color: red;"><b>Yes</b></span>'

      if (se0_name === 'flexible_ticket')
        return [flexible_total[depotCurrency], depotCurrency].join(' ')

      return '-'
    },

    originalPrice({ original_prices: original }) {
      const prices = JSON.parse(original || '{}')
      const { depotCurrency } = this.booking || {}

      if (!Object.values(prices).length) return '-'

      return [prices[depotCurrency], depotCurrency].join(' ')
    },

    diffWrap({ diff, providerCurrency: currency }) {
      if (diff === 0) return '-'

      const cssClass = diff < 0 ? 'red' : 'green'

      return `<span class='${cssClass}'>${Math.abs(diff)} ${currency}</span>`
    }
  }
}
</script>

<style lang="scss" scoped>
.nowrap {
  white-space: nowrap;
}

.marked {
  color: #ffba00;
  background-color: #fff8e6;
}

table {
  line-height: 1;
  min-width: 100%;
  color: #334154;
  border-collapse: collapse;

  thead {
    text-align: left;
    background-color: #f5f5f5;
  }

  tr:not(:first-child) {
    border-top: 1px solid #ececec;
  }

  th,
  td {
    padding: 5px 10px;
  }

  th {
    height: 50px;
    font-size: 13px;
  }

  td {
    height: 40px;
    font-size: 14px;
    transition: background-color 0.15s ease;

    &:not(.skip-hover):hover {
      background-color: #ececec;
    }
  }
}
</style>
