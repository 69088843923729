<template lang="pug">
  .avia-booking-list-table.list-content.pc_list
    el-dialog(title="Booking Comments" :visible.sync="centerDialogVisible" center @closed="comments = undefined")
      el-table.avia-booking-list-table-data(:data="comments" :show-header="false" size="small")
        el-table-column(prop="backendUser.login" width="100px")
        el-table-column
          template(slot-scope="scope")
            div(v-html="scope.row.data")
        el-table-column(width="200px")
          template(slot-scope="scope") {{ new Date(scope.row.createdAt) | moment('LLL') }}
      el-row(:gutter="20" style="margin-top: 20px")
        el-col(:span="16")
          el-input(type="textarea" v-model="comment" :autosize="{ minRows: 7, maxRows: 15 }")
        el-col(:span="8")
          el-checkbox(v-model="attentionComment" style="margin-bottom: 10px") Add as notice
          el-checkbox(v-model="mcoGeneralComment") MCO general comment
      div(slot="footer")
        el-button(type="primary" size="medium" plain @click="centerDialogVisible = undefined") Close
        el-button(type="primary" size="medium" @click="addComment") Add Comment

    el-container(style="max-height: calc(100vh - 240px)")
      el-header(style="height: auto; padding: 0; margin-bottom: 10px")
        top-menu(:currentRow="currentRow" :navigateTab="navigateTab" style="padding: 0 0 0 4px")

      el-main(v-loading="loading" style="padding: 0 10px 0 0")
        el-table.avia-booking-list-table-data(
          size="small"
          :data="records"
          :row-class-name="tableRowClassName"
          :show-header="false"
          @row-dblclick="showDetails"
          highlight-current-row
          @current-change="handleCurrentChange"
        )
          el-table-column(min-width="250" fixed)
            template(slot-scope="scope")
              el-row
                span.pointer(@click.stop="setEditLocator(scope.row.id, scope.row.locator)")
                  template LOCATOR:&nbsp;
                  span.bold(v-if="!editLocators[scope.row.id]" ) {{ scope.row.locator }}
                  el-input(v-else type="string" v-model="editLocators[scope.row.id]" size="mini" style="width: 140px")
                    template(slot="append")
                      el-button-group(style="display: inline-flex")
                        el-button(type="success" icon="el-icon-check" size="mini" plain @click.stop="saveLocator(scope.row.id)")
                        el-button(type="danger" icon="el-icon-close" size="mini" plain @click.stop="setEditLocator(scope.row.id, undefined)")
              el-row ID: {{ scope.row.id }}
              el-row
                template STATUS:&nbsp;
                span.bold {{ scope.row.status }}
              el-row {{ getTripRoute(scope.row.locations) }}
              el-row DEP {{ scope.row.departureTime }}
          el-table-column(min-width="250")
            template(slot-scope="scope")
              el-row Depot: {{ scope.row.depot.name }} [{{ scope.row.depot.id }}]
              el-row
                template Refid:&nbsp;
                span.bold {{ scope.row.trafficSource ? ''.concat(scope.row.trafficSource.name, ' [', scope.row.trafficSource.id, ']') : '—' }}
              el-row Created at: {{ new Date(scope.row.createdAt) | moment('YYYY-MM-DD HH:mm') }}
              el-row Timelimit: {{ new Date(scope.row.timelimit) | moment('YYYY-MM-DD HH:mm') }}
          el-table-column(min-width="350")
            template(slot-scope="scope")
              el-row(v-if="scope.row.testOrder" style="color: red") TEST
              el-row.pointer(@click.native.stop="showComments(scope.row)")
                el-col {{ getLastCommentAuthor(scope.row) }}
                el-col(v-html="getLastCommentText(scope.row)")
</template>

<script>
import BaseTable from '@crud_view/table'
import TopMenu from './top_menu'

export default {
  components: {
    TopMenu
  },
  extends: BaseTable,
  data() {
    return {
      loading: false,
      comments: undefined,
      centerDialogVisible: false,
      locator: 0,
      comment: '',
      currentRow: null,
      attentionComment: false,
      mcoGeneralComment: false,
      editLocators: {}
    }
  },

  methods: {
    async addComment() {
      if (!(this.comment || '').length) return

      try {
        const query = {
          __args: {
            locator: this.locator,
            comment: this.comment,
            table: 'avia_bookings',
            attentionComment: this.attentionComment,
            mcoGeneralComment: this.mcoGeneralComment
          },
          id: true,
          createdAt: true
        }

        await this.graphql('addComment', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Comment added',
          duration: 2600
        })

        this.comment = ''
        this.attentionComment = false
        this.mcoGeneralComment = false

        this.navigateTab({
          reload: true,
          tab: 'items',
          item: 'avia_booking_list'
        })
      } catch (e) {
        this.$notify({ type: 'error', message: e })
      }
    },

    async saveLocator(id) {
      try {
        const { editLocators: { [id]: newLocator } = {} } = this
        const query = { __args: { id, newLocator } }

        await this.graphql('aviaBookingItemSaveLocator', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Locator saved',
          duration: 2600
        })

        this.setEditLocator(id, undefined)

        this.navigateTab({
          reload: true,
          tab: 'items',
          item: 'avia_booking_list'
        })
      } catch (e) {
        this.$notify({ type: 'error', message: e })
      }
    },

    getTripRoute(segments) {
      const route = []
      segments.forEach((segment) => {
        if (route[route.length - 1] === undefined) {
          route.push(segment.departureLocation)
        } else {
          if (route[route.length - 1] !== segment.departureLocation) {
            route.push(segment.departureLocation)
          }
        }
        route.push(segment.arrivalLocation)
      })
      return route.join(' — ')
    },

    getLastCommentAuthor({ comments }) {
      const { createdAt, backendUser } = this._.first(comments) || {}

      if (!createdAt || !backendUser) return '—'

      return [
        this.$moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
        backendUser.login
      ].join(' ')
    },

    getLastCommentText({ comments }) {
      const { data } = this._.first(comments) || {}

      return data || ''
    },

    showComments({ locator, comments }) {
      this.locator = locator
      this.centerDialogVisible = true
      this.attentionComment = undefined
      this.mcoGeneralComment = undefined
      this.comments = comments
      this.comment = ''
    },

    tableRowClassName({ row }) {
      const { payment } = JSON.parse(row.detailedInformation || '{}')

      if (!row) return ''

      if (row.testOrder) return 'status_row_test'

      return ['status_row', row.status]
        .join('_')
        .concat({ air_company: ' AK' }[(payment || {}).system_type] || '')
    },

    showDetails({ id: value }) {
      this.navigateTab({
        tab: 'items',
        item: 'avia_booking_show',
        reload: true,
        filters: { bookings: [{ field: 'id', operator: 'eq', value }] }
      })
    },

    handleCurrentChange(val) {
      if (!val) return
      this.currentRow = val
      this.$elItem.$emit('showInfo', val)
    },

    setEditLocator(bookingId, state) {
      this.editLocators = { ...this.editLocators, [bookingId]: state }
    }
  }
}
</script>

<style lang="scss">
.avia-booking-list-table-data {
  font-size: 13px;
  color: #334154;
  .cell,
  .el-table__cell {
    vertical-align: top;
    line-height: 1.2;
  }
  .el-table__row.current-row {
    box-shadow: -4px 0px 0px 0px red;
  }
  .el-table__body {
    padding-left: 4px;
    .el-dialog__body & {
      padding-left: 0;
    }
  }
  .status_row {
    &_W {
      background-color: #80ffff;
    }
    &_P {
      background-color: #9eff7f;
    }
    &_PN {
      background-color: #ffe681;
      &.AK {
        background-color: #ff9bcc;
      }
    }
    &_PN_autotckt {
      background-color: #cccc99;
    }
    &_CM {
      background-color: #e5cbf1;
    }
    &_CMO {
      background-color: #f1e0cb;
    }
    &_CMP {
      background-color: #cbd3f1;
    }
    &_CMW {
      background-color: #9eff7f;
    }
    &_NP {
      background-color: #70aae9;
    }
    &_WA {
      background-color: #b97a57;
    }
    &_LCP {
      background-color: #00aee1;
    }
    &_manual_book {
      background-color: #e1f3d8;
    }
    &_test {
      background-color: #ffcfcf;
    }
  }
}

$checkbox-props: "[prop='urgent'],[prop='not_actually'],[prop='booking_by_legal_person'],[prop='gmt_only'],[prop='with_special_fare'],[prop='with_special_fare_search'],[prop='fare_downgrade'],[prop='has_ancillary_services'],[prop='has_fare_family'],[prop='from_baggage_tab'],[prop='manual_emd'],[prop='baggage_gds'],[prop='baggage_tab_manual'],[prop='not_urgent'],[prop='urgent_h'],[prop='is_rebooked'],[prop='explorer_check'],[prop='price_component_partially_issued'],[prop='exclude_tickets_kz'],[prop='ground_segments'],[prop='infants'],[prop='is_charter'],[prop='test_order'],[prop='exclude_conv_booking'],[prop='conv_booking'],[prop='self_transfers'],[prop='is_multi_fare'],[prop='is_multi'],[prop='is_multi_gds'],[prop='with_auction'],[prop='ttn_avia_vi'],[prop='with_dynamic_commission'],[prop='price_component_gte'],[prop='price_component_lte'],[prop='is_recommended'],[prop='all_fake_booking'],[prop='all_fake_success'],[prop='fake_only_in_process'],[prop='fake_exclude_in_process'],[prop='fake_only_with_errors'],[prop='fake_pnr_excluded'],[prop='fake_recc_excluded'],[prop='exclude_conso_queue'],[prop='exclude_backlog_queue'],[prop='exclude_autoticketing_queue'],[prop='manual_processing_queue']";

#pane-avia_booking_list {
  div:is(#{$checkbox-props}) span.el-checkbox__input {
    top: 10px;
    left: 10px;
  }

  div.el-form-item {
    &:has(#{$checkbox-props}) {
      .el-form-item__content {
        display: inline-block;
      }
      .el-form-item__label {
        width: 130px;
      }
    }
    &:has([prop='urgent_h'], [prop='price_component_gte'], [prop='price_component_lte']) {
      .el-form-item__content,
      .el-form-item__label {
        width: 75px;
      }
      .el-input {
        top: 10px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.avia-booking-list-table {
  hr {
    border: 0;
    border-top: 1px dotted black;
  }

  .wrap:last-child {
    margin-bottom: 5px;
  }

  .el-dialog .el-row:not(:last-child) {
    margin-bottom: 20px;
  }

  .el-card:not(:last-child),
  .el-card .el-row:not(:last-child) {
    margin-bottom: 5px;
  }
}
</style>
