<template lang="pug">
  extends /table
  block column_data_render
    template(v-slot:baggageDetails="scope")
      div(v-html="formatBaggageDetails(scope.row.baggageDetails)")

</template>

<script>
import BaseTable from '@crud_view/table'

export default {
  components: {
    BaseTable
  },
  extends: BaseTable,
  computed: {
    baggageTypeColors() {
      return {
        baggage: 'green',
        carry_on: 'blue',
        personal_item: 'brown'
      }
    }
  },
  methods: {
    formatBaggageDetails(details) {
      let data  = []

      for (let baggageType of Object.keys(details)) {
        let item = details[baggageType]
        let info = item['ADT']
        let text = `<font color="${this.baggageTypeColors[baggageType]}">${baggageType}</font>: `
        if (item['force']) text += '<b><font color="red">! </font></b>'

        if (info.hasOwnProperty('count') && info.hasOwnProperty('weight')) {
          text += `${info['count']}PC ${info['weight']}${info['weight_unit']}`
        } else if (info.hasOwnProperty('count')) {
          text += `${info['count']}PC`
        } else if (info.hasOwnProperty('weight')) {
          text += `${info['weight']}${info['weight_unit']}`
        }
        data.push(text)
      }

      return data.join('<br>')
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      switch(columnIndex) {
        case 3:
          return { 'color': (row['active'] ? 'green' : 'red') }
        default:
          return {}
      }
    }
  }
}
</script>