import { loadCrudModules } from '@/utils'

export default {
  name: 'uniservice',
  path: '/uniservice',
  alwaysShow: true,
  meta: {
    title: 'uniservice.default',
    icon: 'table'
  },
  children: loadCrudModules(require.context('./uniservice', true, /\.js$/))
}
