import settings from './radar/settings'
import reports from './radar/reports'
import aviaConfigGroups from './radar/avia_config_groups'

export default {
  namespace: 'system_tools',
  component: 'radar',
  meta: {
    title: 'system_tools.radar',
    icon: 'radar'
  },
  tabs: [
    settings,
    aviaConfigGroups,
    reports
  ]
}
