<template lang="pug">
  el-table.avia-booking-inner-table(
    v-bind="{ ...$attrs, data }"
    v-on="$listeners"
    size="small"
  )
    slot
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
.avia-booking-inner-table {
  font-size: 14px;
  color: #334154;
  thead,
  .cell,
  .el-table__header,
  .el-table__body {
    color: inherit;
    text-overflow: initial;
  }
  thead {
    word-break: pre;
  }
  th.el-table__cell.is-leaf,
  .el-table__body tr:hover > td.el-table__cell,
  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #f5f5f5;
  }
  th.el-table__cell.is-leaf {
    font-size: 13px;
  }
  .el-table__row:last-child .el-table__cell {
    border-color: transparent;
  }
  .el-table__empty-block {
    min-height: 40px;
  }
  .el-table__empty-text {
    line-height: 1;
  }
  .el-table__fixed::before,
  .el-table__fixed-right::before,
  &:last-child::before {
    display: none;
  }
}
</style>
