<template lang="pug">
  el-container
    div(v-if="false" @click="loadBookingInfo(38259673)").pointer {{ booking ? '' : 'load' }}

    el-main.avia-booking(v-loading="loading || !records.length" style="padding-top: 0")
      top-menu(:booking="booking" :reloadBooking="loadBookingInfo" :navigateTab="navigateTab")

      el-collapse(v-if="records.length" v-model="activeAreas")
        el-collapse-item.el-collapse-item--old-price(title="Old price table" name="price-details")
          old-price-details(:booking="booking")
        el-collapse-item.el-collapse-item--buyer(title="Buyer info" name="buyer-details")
          buyer-details(:booking="booking")
        el-collapse-item.el-collapse-item--passengers(title="Passengers info" name="passenger-details")
          passenger-details(:booking="booking" :reloadBooking="loadBookingInfo")
        el-collapse-item.el-collapse-item--ancillary(title="Ancillary" name="ancillary-details")
          ancillary-details(:booking="booking" :reloadBooking="loadBookingInfo")
        el-collapse-item.el-collapse-item--price-components(title="Price components" name="price-components")
          price-component-details(:booking="booking")
        el-collapse-item.el-collapse-item--trip-location(title="Segments" name="trip-location-details")
          trip-location-details(:booking="booking" :reloadBooking="loadBookingInfo")
        el-collapse-item.el-collapse-item--upsale(title="Upsale" name="upsale")
          upsale-details(:booking="booking")
        el-collapse-item.el-collapse-item--insurance(title="Insuarance" name="insurance-details")
          insurance-details(:booking="booking" :reloadBooking="loadBookingInfo")
        el-collapse-item.el-collapse-item--pricer(title="Pricer" name="pricer")
          pricer-details(:booking="booking")

    el-aside
      side-menu(:detailedInformation="(booking || {}).detailedInformation")
</template>

<script>
import BookingStruct from '@/settings/modules/avia/general/booking_struct'
import BaseTable from '@crud_view/table'

import SideMenu from './side_menu'
import TopMenu from './top_menu'
import OldPriceDetails from './booking/old_price_details'
import PricerDetails from './booking/pricer_details'
import PriceComponentDetails from './booking/price_component_details'
import TripLocationDetails from './booking/trip_location_details'
import PassengerDetails from './booking/passenger_details'
import AncillaryDetails from './booking/ancillary_details'
import InsuranceDetails from './booking/insurance_details'
import UpsaleDetails from './booking/upsale_details'
import BuyerDetails from './booking/buyer_details'

export default {
  components: {
    SideMenu,
    OldPriceDetails,
    PricerDetails,
    TopMenu,
    PriceComponentDetails,
    TripLocationDetails,
    PassengerDetails,
    AncillaryDetails,
    InsuranceDetails,
    UpsaleDetails,
    BuyerDetails
  },
  extends: BaseTable,
  data() {
    return {
      loading: false,
      activeAreas: ['upsale', 'ancillary-details', 'price-details', 'passenger-details', 'trip-location-details', 'price-components', 'buyer-details']
    }
  },

  computed: {
    booking: {
      get() {
        return this.records[0] || {}
      },
      set(value) {
        return value
      }
    }
  },

  methods: {
    async loadBookingInfo(id) {
      this.loading = true

      try {
        const query = {
          collection: { ...BookingStruct.FullStruct },
          __args: {
            limit: 25,
            page: 1,
            filters: {
              bookings: [{ field: 'id', operator: 'eq', value: id }]
            }
          }
        }

        let result = await this.graphql('aviaBookingItemDetails', query, 'query')

        this.booking = result.collection[0]
      } catch (e) {
        this.$message({
          message: e,
          type: 'error'
        })
      } finally {
        this.loading = undefined
      }
    }
  }
}
</script>

<style lang="scss">
.avia-booking {
  .el-collapse {
    &,
    &-item__wrap {
      border: none;
    }

    &:not(.inactive-price-component) .el-collapse-item {
      margin-bottom: 20px;
    }

    &-item {
      &__header {
        height: 40px;
        font-size: 18px;
        font-weight: 700;
        color: #ffffff;
        padding: 8px 10px;
        border-radius: 4px;
        background-color: #334154;

        &.is-active {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
        &.focusing:focus:not(:hover) {
          color: #ffffff;
        }
      }

      &__content {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-color: #ececec;
        border-width: 0 1px 1px;
        border-style: solid;
        padding-bottom: 0;
        overflow-x: auto;
      }

      &--old-price .el-collapse-item__header {
        background-color: #399e3d;
      }

      &--passengers .el-collapse-item__header {
        background-color: #256ccc;
      }

      &--ancillary {
        .el-collapse-item__header {
          background-color: #6d52d7;
          & > .el-button--primary {
            border-color: transparent;
            &:not(:hover) {
              color: #ffffff;
              background-color: rgba(255, 255, 255, 0.3);
            }
            &:last-of-type {
              margin-right: 10px;
            }
          }
        }
      }

      &--price-components .el-collapse-item__header {
        background-color: #0cad99;
      }

      &--trip-location .el-collapse-item__header {
        background-color: #e88b48;
      }

      &--upsale .el-collapse-item__header {
        background-color: #b949e0;
      }

      &--insurance .el-collapse-item__header {
        background-color: #04b0e6;
      }

      &--pricer .el-collapse-item__header {
        background-color: #ff5353;
      }
    }
  }
}
</style>
