<template lang="pug">
  avia-inner-table(:data="(booking || {}).insuranceBookings || []" size="small")
    el-table-column(label="Locator" prop="reservationId")
    el-table-column(label="Provider" prop="providerName")
    el-table-column(label="Dates (from/to)" prop="periodFrom" :formatter="({ periodFrom, periodTo }) => [periodFrom, periodTo].join(' ... ')")
    el-table-column(label="Cost" prop="depotCost" :formatter="({ depotCost, depotCurrency }) => [depotCost, depotCurrency].join(' ')")
    el-table-column(label="Status" prop="bookingStatus")
</template>

<script>
import AviaInnerTable from './_inner_table'

export default {
  components: { AviaInnerTable },

  props: {
    booking: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
