import general from './analysis/general'

export default {
  namespace: 'orders',
  component: 'analysis',
  meta: {
    title: 'orders.analysis',
    icon: 'skill'
  },
  tabs: [
    general
  ]
}
