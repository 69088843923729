<template lang="pug">
  div(v-if="booking")
    transaction-dialog(:booking="booking" ref="transactionDialog")
    diff-markup-dialog(:booking="booking" ref="diffMarkupDialog")
    cryptic-dialog(:bookingId="booking.id" ref="crypticDialog")
    surcharge-dialog(:locator="booking.locator" ref="surchargeDialog")
    commission-rules(ref="commissionRulesDialog")
    issue-rules(ref="issueRulesDialog")
    booking-credential-dialog(ref="bookingCredentialDialog" :bookingId="booking.id" :reloadBooking="reloadBooking")
    convertor-dialog(:booking="booking" ref="convertorDialog")
    explorer-check-dialog(:booking="booking" ref="explorerCheckDialog")

    el-row
      el-button(type="primary" icon="el-icon-back" plain @click="goBack()") Back
      el-button(type="primary" icon="el-icon-refresh" plain @click="reloadBooking(booking.id)")
      el-button(type="primary" icon="el-icon-link" plain @click="copyToClipboard(shortlink(booking.id), 'Short link copied to clipboard')")

      el-divider(direction="vertical")

      el-button(type="danger" size="mini" plain @click="$refs.transactionDialog.show()") Transactions
      el-button(type="danger" size="mini" plain disabled) Funds unblock

      el-divider(direction="vertical")

      el-button(v-if="checkLegacyPermission(['explorer_check'])"
                type="primary" 
                size="mini" 
                plain 
                :disabled="disableExplCheck()" 
                @click="$refs.explorerCheckDialog.show()") Expl check
      el-button(type="primary" size="mini" plain @click="goToAlternatives()") Alternatives

      el-divider(direction="vertical")

      el-button(type="primary" size="mini" plain @click="$refs.surchargeDialog.show()") Surcharge
      el-button(type="primary" size="mini" plain disabled) Refund
      el-button(type="primary" size="mini" plain @click="$refs.diffMarkupDialog.show()") Addl markup

      el-divider(direction="vertical")

      el-button(type="info" size="mini" plain @click="$refs.crypticDialog.show()") Cryptic
      el-button(type="info" size="mini" plain disabled) Itinerary

      el-divider(direction="vertical")

      el-button(type="danger" size="mini" plain @click="$refs.commissionRulesDialog.show()") Commission
      el-button(type="danger" size="mini" plain @click="$refs.issueRulesDialog.show()") Issue rules

      el-divider(direction="vertical")

      el-button(type="primary" size="mini" plain @click="$refs.bookingCredentialDialog.show()") Credentials

      el-divider(direction="vertical")

      el-button(type="primary" size="mini" plain @click="$refs.convertorDialog.show()") Convertor
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'
import TransactionDialog from './forms/transaction'
import DiffMarkupDialog from './forms/diff_markup'
import CrypticDialog from './forms/cryptic'
import SurchargeDialog from './forms/surcharge'
import CommissionRules from './forms/commission_rules'
import IssueRules from './forms/issue_rules'
import BookingCredentialDialog from './forms/credential'
import ConvertorDialog from './forms/convertor'
import ExplorerCheckDialog from './forms/explorer_check'

export default {
  components: {
    TransactionDialog,
    DiffMarkupDialog,
    CrypticDialog,
    SurchargeDialog,
    CommissionRules,
    IssueRules,
    BookingCredentialDialog,
    ConvertorDialog,
    ExplorerCheckDialog
  },
  mixins: [common, di],
  props: {
    booking: null,
    reloadBooking: null,
    navigateTab: null
  },

  methods: {
    goToAlternatives() {
      const routeData = this.$router.resolve({ path: `/orders/order_items?tab=items&item=avia_bookings&page=1&limit=25&filters[orderItems][0][field]=locator&filters[orderItems][0][operator]=eq&filters[orderItems][0][value]=${this.booking.locator}` })
      this.$store.dispatch('tagsView/addView', routeData.route)
    },

    goBack() {
      this.navigateTab({
        tab: 'items',
        item: 'avia_booking_list',
        reload: false
      })
    },

    disableExplCheck() {
      return this._.indexOf(['PN', 'P'], this.booking.status) == -1
    },

    shortlink(id) {
      return `${window.location.origin}/~/orders-bookings/id/${id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  row-gap: 10px;
  align-items: center;
}
</style>
