export default {
  create(ctx) {
    return {
      legend: 'settings',
      translateLegend: true,
      items: [
        {
          name: 'settings__limit',
          type: 'text',
          default: '10', 
          required: true
        },
        {
          name: 'settings__apiKey',
          type: 'text',
          required: true
        },
        {
          name: 'settings__popularDateRange',
          type: 'select',
          storeKey: 'range',
          required: true
        },
        {
          name: 'settings__popularDateShift',
          type: 'text',
          required: true
        }
      ]
    }
  }
}