export default {
  create(ctx) {
    return {
      legend: 'settings',
      translateLegend: true,
      items: [
        {
          name: 'settings__price',
          type: 'text',
          required: false
        },
        {
          name: 'settings__supplier',
          type: 'text',
          required: false
        },
        {
          name: 'settings__configId',
          type: 'text',
          required: false
        },
        {
          name: 'settings__dateShift',
          type: 'text',
          required: true
        },
        {
          name: 'settings__date',
          type: 'date',
          options: {
            firstDayOfWeek: 1
          },
          valueFormat: 'yyyy-MM-dd',
          required: false
        }
      ]
    }
  }
}